import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        userInfo:{}
    },
    mutations:{
        setUserInfo(state, userInfo){
            state.userInfo = userInfo
        },
        reset(state){
            state.userInfo = {};
        }
    },
    getters:{

    },
    actions:{
        resetAction({ commit }){
            commit('reset')
        }
    },
    modules:{
        
    },
    plugins: [createPersistedState()]
})