<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入地址">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
                <Button type="error" @click="reset()" style="margin-left:10px" >重置</Button>
            </FormItem>
        </Form>
         <Button type="success" @click="add()" style="margin-bottom:10px" >新增</Button>

         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>

        <Modal
            v-model="show_edit" :mask-closable="false" footer-hide
            :title="show_title">
            <Form  :model="submitForm"  :label-width="80">
                <FormItem label="地址" prop="name">
                    <Input type="text" v-model="submitForm.name" placeholder="请输入地址">
                    </Input>
                </FormItem>
                <FormItem label="详情" prop="address">
                    <Input type="text" v-model="submitForm.address" placeholder="请输入地址详情">
                    </Input>
                </FormItem>
                <FormItem>
                    <Button type="error" @click="save()" style="margin-left:10px" >保存</Button>
                </FormItem>
            </Form>
        </Modal>
       <Modal v-model="mapShow"
       title="地图定位"
       @on-ok="okMap"
       :mask-closable="false"
       @on-cancel="cancelMap">
            <i-form  ref="formInline" inline>
                <Form-Item>
                    <i-input placeholder="请输入地址" style="width:260px" type="text" v-model="search_address_data.address"></i-input>
                </Form-Item>
                <Form-Item  >
                    <i-button type="primary"  @click="search_address">搜索</i-button>
                </Form-Item>
            </i-form>
            <div id="container" style="width:500px;height:400px"></div>
        </Modal>
    </div>
</template>
 

<script>
var that;
    import { TMap } from '@/components/TMap'
    import {book_addPlaceApi, book_placeListApi, book_delPlaceApi, book_updateLatlng, book_getLatlng} from '@/api/book';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                Accept:"png,jpg,jpeg",
                Format: ["jpg", "jpeg", "png"],
                submitForm:{
                    name:"",
                    address:""
                },
                map:{
                    lat:"",
                    lng:""
                },
                search_address_data:{
                    address:""
                },
                mapShow:false,
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '#',
                            type: 'index',
                            width: 80,
                            align:'center'
                        },{
                            title: '地址',
                            key: 'name',
                            width: 300,
                            align:'center'
                        },
                        {
                            title: '具体地址',
                            align:'center',
                            key: 'address'
                        },
                        {
                            title: '定位',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                var text = "查看", text_type = "primary";
                                if (params.row.lat == "") {
                                    text = "待定位"; text_type = "error";   
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: text_type,
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.showMap(params.row)
                                            }
                                        }
                                    }, text)
                                ]);
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.show(params.row)
                                            }
                                        }
                                    }, '编辑'),
                                    h('Button', {
                                        props: {
                                            type: 'error',
                                            size: 'small'
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row)
                                            }
                                        }
                                    }, '删除')
                                ]);
                            }
                        }
                    ],
                    data: []
                },
                
                formInline:{}
            }
        },
        mounted(){
            this.loadList();
            that = this;
        },
        methods: {
            showMap(params){
                this.submitForm = params;
                this.initMap();
                this.mapShow = true;
            },
            initMap(){
                this.map.lat = this.submitForm.lat ? this.submitForm.lat : this.$store.state.userInfo.lat;
                this.map.lng = this.submitForm.lat ? this.submitForm.lng : this.$store.state.userInfo.lng;
                this.initQQMap();
            },
            initQQMap(){
                TMap().then(qq => {
                    var map_center = new qq.maps.LatLng( that.map.lat,  that.map.lng);
                    var map = new qq.maps.Map(document.getElementById("container"), {
                        // 地图的中心地理坐标。
                        center: map_center,
                        zoom: 8
                    });
                    var map_marker = new qq.maps.Marker({
                        position: map_center,
                        map: map,
                    });
                    qq.maps.event.addListener(map,'click',function(event) {
                        var lng = event.latLng.getLng(), lat = event.latLng.getLat();
                        var new_center = new qq.maps.LatLng(lat,lng);
                        if (map_marker != undefined) {
                            map_marker.setMap(null)
                        }
                        map_marker = new qq.maps.Marker({
                            position: new_center,
                            map: map,
                        });
                        that.submit_garage_lgtlng(lat, lng);
                    });
                });
            },
            search_address(){
                if (this.search_address_data.address == "") {
                    this.$Message.error("搜索地址不能为空");
                    return false;
                }
                book_getLatlng(this.search_address_data).then(res=>{
                    this.map.lat = res.lat;
                    this.map.lng = res.lng;
                    this.initQQMap();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },

            submit_garage_lgtlng(lat, lng){
                book_updateLatlng({id:this.submitForm.id, lat:lat, lng:lng}).then(res=>{
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },

            okMap(){

            },
            cancelMap(){

            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    name:"",
                    address:""
                }
            },
            
            save(){
                if(this.submitForm.name == "") {
                    this.$Message.error("地点名称不能为空");return;
                }
                if(this.submitForm.address == "") {
                    this.$Message.error("地址不能为空");return;
                }
                book_addPlaceApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            show (params) {
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = params;
            },
            remove (params) {
                
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该地址？</p>',
                    onOk: () => {
                        this.delDoctor(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            
            delDoctor(id){
                book_delPlaceApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            reset(){
                this.formInline = {
                    name:""
                }
                this.main_table.current = 1;
                this.loadList();
            },
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                book_placeListApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            }
        }
    }
</script>
