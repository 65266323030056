/** 首页路由 */
export default[
    {
        path:'/',
        name:'index',
        meta: { title: '首页' },
        component: ()=>import('@/pages/index/index.vue')
    },
    {
        path:'/banner',
        name:'banner',
        meta: { title: 'banner管理' },
        component: ()=>import('@/pages/index/banner.vue')
    }
]