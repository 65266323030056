<template>
    <Layout  :style="'min-height:'+ innerHeight + 'px'">
            <Header>
                <Menu mode="horizontal" theme="dark" active-name="logout" @on-select="logout" >

                    <div class="layout-logo">
<!--                        <img style="width:100%;height:100%" src="../../assets/logo.jpg" />-->
                        {{plat_title}}
                    </div>
                    <div class="layout-nav">
                        <span v-if="userInfo.enddate" style="color:#ffffff">到期时间：{{userInfo.enddate}}</span>
                        <MenuItem name="logout">
                           <Icon type="ios-log-out" />
                            {{userInfo.nickname}}
                        </MenuItem>
                    </div>
                </Menu>
            </Header>
            <Layout>
                <Sider hide-trigger :style="{background: '#fff'}">
                    <Menu :active-name="String($route.name)" ref="menu" theme="light" width="auto" :open-names="openNames" >
                        <MenuItem v-if=" userInfo.type !== 3 && userInfo.type !== 5" name="index" to="/" >
                          <Icon type="ios-menu-outline" />
                            首页
                        </MenuItem>

                        <Submenu name="orders" v-if="userInfo.type !== 5" >
                            <template slot="title">
                              <Icon type="ios-cash-outline" />
                                入账管理
                            </template>
                            <MenuItem name="orders-bookkeeping" v-if="userInfo.type !== 4" to="/orders/bookkeeping" >会员入账</MenuItem>
                            <MenuItem name="orders-examine" v-if="userInfo.type !== 2 && userInfo.type !== 3" to="/orders/examine" >入账审核</MenuItem>
                            <MenuItem name="orders-history" to="/orders/history" >历史入账</MenuItem>
                            <MenuItem name="orders-entryitem" v-if="userInfo.type == 1 || userInfo.type == 6 || userInfo.type == 4 " to="/orders/entryitem" >入账项目</MenuItem>
                        </Submenu>
                        <Submenu name="book"  v-if=" userInfo.type !== 2 && userInfo.type !== 4" >
                            <template slot="title">
                              <Icon type="md-alarm" />
                                预约订单
                            </template>
                            <MenuItem name="book-index" to="/book/index" >待处理</MenuItem>
                            <MenuItem name="book-history" to="/book/history" >历史订单</MenuItem>
                        </Submenu>
                        <MenuItem name="customer" to="/customer"  v-if="userInfo.type !== 5" >
                           <Icon type="ios-card" />
                            会员管理
                        </MenuItem>
                        <Submenu name="yuyue" v-if="userInfo.type !== 4 && userInfo.type !== 2 && userInfo.type !== 3">
                            <template slot="title">
                              <Icon type="ios-alarm-outline" />
                                预约管理
                            </template>
                            <MenuItem name="yuyue-manager" to="/yuyue/manager" >预约设置</MenuItem>
                            <MenuItem name="yuyue-doctor" to="/yuyue/doctor" >人员列表</MenuItem>
                            <MenuItem name="yuyue-place" to="/yuyue/place" >地点管理</MenuItem>
                        </Submenu>
                      <MenuItem name="money" to="/money"  v-if="userInfo.type == 1 ||   userInfo.type == 6" >
                        <Icon type="ios-cart-outline" />
                        消费管理
                      </MenuItem>
                        <Submenu name="finace"  v-if="userInfo.type == 1 || userInfo.type == 4 ||  userInfo.type == 6">
                            <template slot="title">
                              <Icon type="logo-yen" />
                                财务提现
                            </template>
                            <MenuItem name="finace-index" to="/finace/index" >提现审核</MenuItem>
                            <MenuItem name="finace-history" to="/finace/history" >历史记录</MenuItem>
                        </Submenu>

                        <Submenu name="person"  v-if="userInfo.type == 1 ||   userInfo.type == 6">
                            <template slot="title">
                              <Icon type="ios-contacts" />
                                人员管理
                            </template>
                            <MenuItem name="person-channel" to="/person/channel" >服务商</MenuItem>
                            <MenuItem name="person-yuangong" to="/person/yuangong" >员工管理</MenuItem>
                        </Submenu>
                        <MenuItem name="share" to="/share"  v-if="userInfo.type == 1 ||   userInfo.type == 6" >
                          <Icon type="ios-settings-outline" />
                            基本设置
                        </MenuItem>
                        <MenuItem name="banner" to="/banner" v-if="userInfo.type == 1 ||   userInfo.type == 6 ||   userInfo.type == 5" >
                          <Icon type="ios-images-outline" />
                            banner管理
                        </MenuItem>
                        <MenuItem name="modify" to="/modify" >
                          <Icon type="ios-hammer-outline" />
                            修改密码
                        </MenuItem>
                    </Menu>
                </Sider>
                <Layout :style="{padding: '0 24px 24px'}">
                    <Breadcrumb :style="{margin: '24px 0'}">
                        <BreadcrumbItem  v-for="(item, index) in $route.matched" :key="index" >{{item.meta.title}}</BreadcrumbItem>
                    </Breadcrumb>
                    <Content :style="{padding: '24px', minHeight: '280px', background: '#fff'}">
                        <router-view></router-view>
                    </Content>
                </Layout>
            </Layout>
        </Layout>
</template>
<script>
import {windowsRise} from '@/utils/dom';
import {getCookies} from "@/utils/helper";
import {logoutApi} from '@/api/login';
import config from '@/config';
export default {
    data(){
        return{
            innerHeight:0,
            openNames:[],
            userInfo:{},
            plat_title:config.title
        }
    },
    mounted(){
        this.restHeight();
        windowsRise(window, this.restHeight);
        this.selectMenu();
        let token = getCookies("token");
        if(token == "") {
             this.logout();
        }

        let infos = getCookies(location.hostname)
        if (infos) {
            this.plat_title = infos.title ? infos.title : config.title;
        }
        this.userInfo = this.$store.state.userInfo;
    },
     watch: {
        '$route.path'() {
            this.selectMenu();
        }
    },
    methods:{
        /**修改高度 */
        restHeight(){
            this.innerHeight =  window.innerHeight - 2;
        },
        /**修改导航展开样式 */
        selectMenu(){
            this.openNames = [this.$route.name.split('-')[0]];
            this.$nextTick(()=>{
                this.$refs.menu.updateOpened();
            })
        },
        logout(){

            logoutApi().then(res => {
                this.$router.push({path: '/login'})
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        }
    }

}
</script>

<style>
.layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout-logo {
    width: 150px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffd878;
}
.layout-nav{
    width: 300px;
    margin: 0 auto;
    margin-right: 20px;
}
.ivu-menu-horizontal .ivu-menu-item{
  float:right!important;
}
.ivu-breadcrumb{
    color:#fbb200 !important;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after{
    background:#fbb200 !important;
}
.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
    color: #fbb200 !important;
    background: #f7f7f7 !important;
}
</style>
