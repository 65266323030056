import request from '@/utils/request'

export function loginApi (data) {
    return request({
        url: 'login',
        method: 'post',
        data
    })
}
export function jumpApi (data) {
    return request({
        url: 'jump',
        method: 'post',
        data
    })
}

export function loginInfoApi (data) {
    return request({
        url: 'login_info',
        method: 'post',
        data
    })
}

export function logoutApi (data) {
    return request({
        url: 'logout',
        method: 'post',
        data
    })
}

export function passWordModify (data) {
    return request({
        url: 'modify_pass',
        method: 'post',
        data
    })
}

// 分销设置
export function share_Api (data) {
    return request({
        url: 'finace_share',
        method: 'post',
        data
    })
}
// 分销设置
export function get_share_Api (data) {
    return request({
        url: 'return_share',
        method: 'post',
        data
    })
}


export function delImg (data) {
    return request({
        url: '/del_img',
        method: 'post',
        data
    })
}