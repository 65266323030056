import request from '@/utils/request'

export function userListApi (data) {
    return request({
        url: 'user/return_list',
        method: 'get',
        params: data
    })
}

// 会员列表
export function member_listApi (data) {
    return request({
        url: 'member/member_list/',
        method: 'post',
        data
    })
}
// 添加会员
export function member_addApi (data) {
    return request({
        url: 'member/member_add',
        method: 'post',
        data
    })
}
// 会员下拉框
export function member_selectApi (data) {
    return request({
        url: 'member/member_select/',
        method: 'post',
        data
    })
}
// 删除会员
export function member_delApi (data) {
    return request({
        url: 'member/member_del',
        method: 'post',
        data
    })
}
// 获取消费记录
export function money_list (data) {
    return request({
        url: 'member/money_list',
        method: 'post',
        data
    })
}

// 修改会员分享
export function member_shareApi (data) {
    return request({
        url: 'member/member_share',
        method: 'post',
        data
    })
}


// 员工、服务商列表
export function person_listApi (data) {
    return request({
        url: 'person/person_list',
        method: 'post',
        data
    })
}
// 添加员工、服务商
export function person_addApi (data) {
    return request({
        url: 'person/person_add',
        method: 'post',
        data
    })
}
// 删除员工、服务商
export function person_delApi (data) {
    return request({
        url: 'person/person_del',
        method: 'post',
        data
    })
}
// 入账
export function entry_addApi (data) {
    return request({
        url: 'entry/entry_add',
        method: 'post',
        data
    })
}

// 人员下拉
export function person_selectApi (data) {
    return request({
        url: 'person/person_select',
        method: 'post',
        data
    })
}
// 入账列表
export function entry_listApi (data) {
    return request({
        url: 'entry/entry_list',
        method: 'post',
        data
    })
}
// 入账列表图片
export function entry_imageApi (data) {
    return request({
        url: 'entry/entry_imagelist',
        method: 'post',
        data
    })
}


// 删除入账
export function entry_delApi (data) {
    return request({
        url: 'entry/entry_del',
        method: 'post',
        data
    })
}

// 入账状态修改
export function entry_statusApi (data) {
    return request({
        url: 'entry/entry_status',
        method: 'post',
        data
    })
}


// 获取个人入账
export function entry_getApi (data) {
    return request({
        url: 'member/entry_list',
        method: 'post',
        data
    })
}


// 项目列表
export function item_listApi (data) {
    return request({
        url: 'item/item_list',
        method: 'post',
        data
    })
}
// 添加项目
export function item_addApi (data) {
    return request({
        url: 'item/item_add',
        method: 'post',
        data
    })
}
// 删除项目
export function item_delApi (data) {
    return request({
        url: 'item/item_del',
        method: 'post',
        data
    })
}

// 项目列表
export function item_selectApi (data) {
    return request({
        url: 'item/item_select',
        method: 'post',
        data
    })
}

// 资金明细
export function order_infoApi (data) {
    return request({
        url: 'member/money_info',
        method: 'post',
        data
    })
}

// 资金明细
export function member_infosApi (data) {
    return request({
        url: 'entry/member_info',
        method: 'post',
        data
    })
}

// 导出数据
export function export_infoApi (data) {
    return request({
        url: 'entry/export',
        method: 'post',
        data
    })
}
