<template>
        <div   :style="'height:'+ innerHeight + 'px;'">
            <div v-show="loading"  class="loading-icon" :style="'height:'+ innerHeight + 'px;'" >
                <Spin fix size="large" ></Spin>
            </div>
            <div v-show="!loading">
                <div class="bg" :style="' background: url('+login_info.bg+') no-repeat;'" ></div>
                 <div class="e">
                     <Card  style="width:500px;border: 1px solid #eeeeee; " shadow  bordered :title="login_info.title">
                        <div style="margin:20px 30px">

                            <Form ref="formInline" :model="formInline" >
                                <FormItem prop="nickname">
                                    <Input type="text" v-model="formInline.nickname" placeholder="请输入账户">
                                        <Icon size="18" type="ios-person-outline" slot="prepend"></Icon>
                                    </Input>
                                </FormItem>
                                <FormItem prop="password">
                                    <Input type="password" v-model="formInline.password" placeholder="请输入密码">
                                        <Icon size="18" type="ios-lock-outline"  slot="prepend"></Icon>
                                    </Input>
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" @click="handleSubmit()">登录</Button>
                                </FormItem>
                            </Form>
                        </div>
                    </Card>
                 </div>
            </div>
        </div>
</template>
<script>
import {windowsRise} from '@/utils/dom';
import {loginApi,loginInfoApi } from '@/api/login'
import config from '@/config';

import {setCookies, setIco} from "@/utils/helper";
    export default {
        data () {
            return {
                innerHeight: 0,
                formInline: {
                    nickname: '',
                    password: ''
                },
                login_info:{
                    bg:require("@/assets/bg.jpg"),
                    ico:require("@/assets/ico.png"),
                    title:config.title
                },
                domain:"",
                loading:true
            }
        },
        created(){
            this.domain = location.hostname
            this.loginInfo();
        },
        mounted(){
            this.restHeight();
            windowsRise(window, this.restHeight);
             let _this = this
            document.onkeydown = function(e) {
                if(e.keyCode === 13){
                    _this.handleSubmit() //这里调用的是点击按钮登录的事件
                }
            }
        },
        methods: {
            loginInfo(){
                loginInfoApi({domain:this.domain}).then(res=>{
                    this.loading = false;
                    this.login_info =  {
                        bg:res.data.bg ? res.data.bg : this.login_info.bg,
                        title:res.data.title ? res.data.title : this.login_info.title,
                        ico:res.data.ico?res.data.ico:this.login_info.ico
                    }
                    window.document.title = this.login_info.title
                    setIco(this.login_info.ico);
                    setCookies(this.domain, this.login_info)
                }).catch(err=>{
                    this.loading = false;
                })
            },
            /**修改高度 */
            restHeight(){
                this.innerHeight =  window.innerHeight - 2;
            },
            handleSubmit() {
                if (this.formInline.nickname == "") {
                   this.$Message.error("账户不能为空");return;
                }
                if (this.formInline.password == "") {
                   this.$Message.error("密码不能为空");return;
                }
                loginApi(this.formInline).then(res => {
                        this.$Message.success(res.msg)
                        this.$store.commit('setUserInfo', res.data)
                        setCookies('token', res.token)
                        let path = "/";
                        if (res.data.type == 2 || res.data.type == 3 || res.data.type == 5) {
                            path = "/orders/bookkeeping"
                        }
                        this.$router.push({path: path})
                }).catch(res => {
                    this.$Message.error(res.msg)
                })
            }
        }
    }
</script>
<style scoped>
.loading-icon{
    display: inline-block;width: 100%;height: 500px;position: relative;border: 1px solid #eee;
}
.e{
    margin-top:-20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
}
.bg{
    background-size: 100% 100% !important;
    width:100%;height:100%;position:absolute;z-index:1;
}
</style>
