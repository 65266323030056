<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入项目名称">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
            </FormItem>
        </Form>
        <Button  v-if="userInfo.type != 4" type="success" @click="add()" style="margin-bottom:10px" >新增</Button>
        <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>



        <Modal
                v-model="show_edit" :mask-closable="false" footer-hide
                :title="show_title">
            <Form  :model="submitForm"  :label-width="100">
                <FormItem label="项目名称" prop="name">
                    <Input type="text" v-model="submitForm.name" placeholder="请输入项目名称">
                    </Input>
                </FormItem>
                <FormItem label="项目金额" prop="money">
                    <Input type="text" v-model="submitForm.money" placeholder="请输入项目金额">
                    </Input>
                </FormItem>
                <FormItem label="一级分销" prop="oneshare">
                    <Input type="text" v-model="submitForm.oneshare" placeholder="请输入一级分销，例：0.15 为15%">
                    </Input>
                </FormItem>
                <FormItem label="二级分销" prop="twoshare">
                    <Input type="text" v-model="submitForm.twoshare" placeholder="请输入二级分销，例：0.15 为15%">
                    </Input>
                </FormItem>
                <FormItem label="三级分销" prop="threeshare">
                    <Input type="text" v-model="submitForm.threeshare" placeholder="请输入三级分销，例：0.15 为15%">
                    </Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="save()" style="margin-left:10px" >保存</Button>
                    <Button type="error" @click="cancel()" style="margin-left:10px" >取消</Button>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>
<script>
    import {item_listApi, item_addApi, item_delApi} from '@/api/customer';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                submitForm:{
                    nickname:"",
                    phone:"",
                    password:"",
                    oneshare:"",
                    twoshare:"",
                    threeshare:""
                },
                userInfo:{},
                formInline:{
                    name:""
                },
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                        title: '#',
                        type: 'index',
                        align:'center',
                        width: 80
                    },{
                        title: '项目名称',
                        key: 'name',
                        width: 300,
                        align:'center'
                    },
                        {
                            title: '项目金额',
                            align:'center',
                            key: 'money'
                        },
                        {
                            title: '一级分销',
                            align:'center',
                            key: 'oneshare'
                        },
                        {
                            title: '二级分销',
                            align:'center',
                            key: 'twoshare'
                        },
                        {
                            title: '三级分销',
                            align:'center',
                            key: 'threeshare'
                        },
                        {
                            title: '创建时间',
                            align:'center',
                            width: 180,
                            key: 'ctime'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                if (this.userInfo.type != 4) {
                                                    this.edit(params.row);
                                                }
                                            }
                                        }
                                    }, '编辑'),
                                    h('Button', {
                                        props: {
                                            type: 'error',
                                            size: 'small'
                                        },
                                        on: {
                                            click: () => {
                                                if (this.userInfo.type != 4) {
                                                    this.remove(params.row)
                                                }
                                            }
                                        }
                                    }, '删除')
                                ]);
                            }
                        }
                    ],
                    data: []
                }
            }
        },
        mounted(){

            this.userInfo = this.$store.state.userInfo;
            this.loadList();
        },
        methods: {
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                item_listApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            },
            cancel(){
                this.show_edit = false;
            },
            edit(p){
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = {
                    id:p.id,
                    name:p.name,
                    money:p.money,
                    oneshare:p.oneshare,
                    twoshare:p.twoshare,
                    threeshare:p.threeshare
                }
            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    name:"",
                    money:"",
                    oneshare:"",
                    twoshare:"",
                    threeshare:""
                }
            },
            save(){
                if(this.submitForm.name == "") {
                    this.$Message.error("项目名称不能为空");return;
                }
                if(this.submitForm.money == "") {
                    this.$Message.error("项目金额不能为空");return;
                }
                item_addApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            remove (params) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该项目？</p>',
                    onOk: () => {
                        this.delMember(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            delMember(id){
                item_delApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
        }
    }
</script>
