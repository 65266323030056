<template>
    <div>
         <Button type="success" @click="add()" style="margin-bottom:10px" >新增</Button>

         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>

        <Modal
            v-model="show_edit" :mask-closable="false" footer-hide
            :title="show_title">
            <Form  :model="submitForm"  :label-width="80">
                <FormItem label="地址" prop="name">
                    <Select v-model="submitForm.place_id" placeholder="请选择地址">
                        <Option v-for="(p, i) in places" :key="i" :value="p.id">{{p.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="人员" prop="doctor_id">
                    <Select v-model="submitForm.doctor_id"  placeholder="请选择人员">
                        <Option v-for="(d, i) in doctors"  :key="i"  :value="d.id">{{d.name}}</Option>
                    </Select>
                </FormItem>
                 <FormItem label="日期" prop="date">
                    <DatePicker type="daterange" placeholder="选择日期" @on-change="changeDate" v-model="submitForm.date"></DatePicker>
                </FormItem>
                <FormItem label="时间段1" prop="time1">
                     <TimePicker format="HH:mm"  placeholder="选择时间段1"  type="timerange" v-model="submitForm.time1" placement="bottom-end"  style="width: 168px"></TimePicker>
                </FormItem>
                <FormItem label="时间段2" prop="time2">
                    <TimePicker format="HH:mm" placeholder="选择时间段2" type="timerange" v-model="submitForm.time2" placement="bottom-end" style="width: 168px"></TimePicker>
                </FormItem>
                <FormItem label="时间段3" prop="time3">
                    <TimePicker format="HH:mm" placeholder="选择时间段2" type="timerange" v-model="submitForm.time3" placement="bottom-end" style="width: 168px"></TimePicker>
                </FormItem>
                <FormItem>
                    <Button type="error" @click="save()" style="margin-left:10px" >保存</Button>
                </FormItem>
            </Form>
        </Modal>

    </div>
</template>
<script>
    import {book_settingListApi, book_settingAddtApi, book_doctorSelectApi, book_placeSelectApi} from '@/api/book';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                Accept:"png,jpg,jpeg",
                Format: ["jpg", "jpeg", "png"],
                submitForm:{
                    place_id:"",
                    doctor_id:"",
                    date:"",
                    time1:"",
                    time2:"",
                    time3:""
                },
                doctors:[],
                places:[],
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '地址',
                            key: 'place_name',
                            width: 300,
                            align:'center'
                        },
                        {
                            title: '人员',
                            align:'center',
                            key: 'doctor_name'
                        },
                        {
                            title: '日期',
                            align:'center',
                            key: 'address',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, params.row.start_date+'-'+params.row.end_date)
                                ]);
                            }
                        },
                        {
                            title: '时间段1',
                            align:'center',
                            key: 'time1',
                            render: (h, params) => {
                                let timetext = "";
                                if (params.row.start_time1 !="" &&　params.row.start_time1 != null) {
                                    timetext = params.row.start_time1+'-'+params.row.end_time1
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, timetext)
                                ]);
                            }
                        },
                        {
                            title: '时间段2',
                            align:'center',
                            key: 'time2',
                            render: (h, params) => {
                                let timetext = "";
                                if (params.row.start_time2 !="" &&　params.row.start_time2 != null) {
                                    timetext = params.row.start_time2+'-'+params.row.end_time2
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, timetext)
                                ]);
                            }
                        },
                        {
                            title: '时间段2',
                            align:'center',
                            key: 'time3',
                            render: (h, params) => {
                                let timetext = "";
                                if (params.row.start_time3 !="" &&　params.row.start_time3 != null) {
                                    timetext = params.row.start_time3+'-'+params.row.end_time3
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, timetext)
                                ]);
                            }
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.show(params.row)
                                            }
                                        }
                                    }, '编辑'),
                                    h('Button', {
                                        props: {
                                            type: 'error',
                                            size: 'small'
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row)
                                            }
                                        }
                                    }, '删除')
                                ]);
                            }
                        }
                    ],
                    data: []
                }
            }
        },
        mounted(){
            this.getDoctorPlace();
            this.loadList();
        },
        methods: {
            getDoctorPlace(){
                book_doctorSelectApi().then(res=>{
                    this.doctors = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
                book_placeSelectApi().then(res=>{
                    this.places = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            loadList(){
                book_settingListApi({limit:this.main_table.limit, page:this.main_table.current}).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                })
            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    place_id:"",
                    doctor_id:"",
                    date:"",
                    time1:"",
                    time2:"",
                    time3:""
                }
            },
            changeDate(date, formContent){
                this.submitForm.start_date = date[0]
                this.submitForm.end_date = date[1]
            },
            save(){
                if (this.submitForm.place_id == "") {
                     this.$Message.error("请选择地址");return;
                }
                if (this.submitForm.doctor_id == "") {
                     this.$Message.error("请选择人员");return;
                }
                if (this.submitForm.date == "") {
                     this.$Message.error("请选择日期");return;
                }
                if ( (this.submitForm.time1 == "" || this.submitForm.time1 == undefined)&&  (this.submitForm.time2 == "" ||  this.submitForm.time2 == undefined)&& ( this.submitForm.time3 == "" ||  this.submitForm.time3 == undefined) ) {
                     this.$Message.error("请至少选择一个时间段");return;
                }
                if(this.submitForm.time1 != ""  && this.submitForm.time1 != undefined) {
                    this.submitForm.start_time1  = this.submitForm.time1[0];
                    this.submitForm.end_time1  = this.submitForm.time1[1];
                }
                if(this.submitForm.time2 != ""  && this.submitForm.time2 != undefined) {
                    this.submitForm.start_time2  = this.submitForm.time2[0];
                    this.submitForm.end_time2  = this.submitForm.time2[1];
                }
                if(this.submitForm.time3 != "" && this.submitForm.time3 != undefined) {
                    this.submitForm.start_time3  = this.submitForm.time3[0];
                    this.submitForm.end_time3  = this.submitForm.time3[1];
                }

                book_settingAddtApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            show (params) {
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = params;

                if (params.start_date != ""){
                    this.submitForm.date = params.start_date+' - '+params.end_date
                }

                if ( params.start_time1 != "" &&　 params.start_time1 != null) {
                    this.submitForm.time1 = params.start_time1+' - '+params.end_time1
                }
                if ( params.start_time2 != "" &&　 params.start_time2 != null) {
                    this.submitForm.time2 = params.start_time2+' - '+params.end_time2
                }
                if ( params.start_time3 != "" &&　 params.start_time3 != null) {
                    this.submitForm.time3 = params.start_time3+' - '+params.end_time3
                }

            },
            remove () {

                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该预约设置？</p>',
                    onOk: () => {
                    },
                    onCancel: () => {
                    }
                });
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            }
        }
    }
</script>
