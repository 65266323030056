<template>
    <div>
          <Form ref="formInline" :model="formInline" :label-width="80" >
            <FormItem prop="pwd" label="原始密码">
                <Input type="password" v-model="formInline.pwd" placeholder="请输入原始密码" style="width:200px">
                </Input>
            </FormItem>
            <FormItem prop="newpwd" label="新密码">
                <Input type="password" v-model="formInline.newpwd" placeholder="请输入新密码" style="width:200px">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="error" @click="save()" style="margin-left:10px" >保存</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
import {passWordModify} from '@/api/login';
    export default {
        data () {
            return {
                formInline:{
                    pwd:"",
                    newpwd:""
                }
            }
        },
        mounted(){
        },
        methods: {
            save(){
                if (this.formInline.pwd == "") {
                     this.$Message.error("原始密码不能为空");return;
                }
                if (this.formInline.newpwd == "") {
                     this.$Message.error("新密码不能为空");return;
                }
                
                passWordModify(this.formInline).then(res=>{
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            }
        }
    }
</script>
