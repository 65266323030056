<template>
    <div>

        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入用户名称或电话">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
            </FormItem>
        </Form>
        <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>

        <Form inline v-if="userInfo.type != 2 && userInfo.type != 3 && userInfo.type != 5 ">
            <FormItem prop="name">
                <DatePicker type="daterange"  format="yyyy-MM-dd" @on-change="changedate"   placement="bottom-start" placeholder="选择日期" style="width: 200px"></DatePicker>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="importXls()" >导出</Button>
            </FormItem>
        </Form>


        <Modal
                v-model="show_edit" :mask-closable="false" footer-hide
                :title="show_title">
            <Form  :model="submitForm"  :label-width="100">
                <FormItem label="所属服务商" prop="channel_name">
                    <Input type="text" v-model="submitForm.channel_name" placeholder="所属服务商">
                    </Input>
                </FormItem>
                <FormItem label="会员" prop="member_name">
                    <Input type="text" v-model="submitForm.member_name" placeholder="会员">
                    </Input>
                </FormItem>
                <FormItem label="选择时间" prop="shopdate">
                    <DatePicker type="datetime" v-model="submitForm.shopdate" placeholder="请选择消费时间" style="width: 200px"></DatePicker>
                </FormItem>
                <FormItem label="消费项目" v-if="!submitForm.formDynamic">
                    <Select v-model="submitForm.items" multiple>
                        <Option v-for="item in itemList"   :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem
                        v-if="submitForm.formDynamic"
                        v-for="(item, index) in submitForm.formDynamic"
                        :key="index"
                        label="消费项目">
                    <Row :gutter="10">
                        <Col span="14">
                            <Select v-model="item.id"  @on-change="changeMoney(1)"  filterable >
                                <Option v-for="it in itemList"   :value="it.id" :key="it.id">{{ it.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="4">
                            <Input type="text" @on-change="changeMoney(2)" v-model="item.value" placeholder="次数"></Input>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="其他金额" prop="othermoney">
                    <Input type="text" v-model="submitForm.other_money" placeholder="请输入其他金额">
                    </Input>
                </FormItem>
                <FormItem label="优惠金额" prop="couponmoney">
                    <Input type="text" v-model="submitForm.coupon_money"  placeholder="请输入优惠金额">
                    </Input>
                </FormItem>
                <FormItem label="总消费金额" prop="money">
                    <Input type="text" v-model="submitForm.money" placeholder="请输入消费金额">
                    </Input>
                </FormItem>

                <FormItem label="消费凭证" >

                    <Upload
                            ref="uploadimg"
                            action="/"
                            :default-file-list="submitForm.defaultBase64"
                            :on-preview="preview"
                    >
                        <Button icon="ios-cloud-upload-outline">请选择消费凭证</Button>
                    </Upload>
                </FormItem>
                <FormItem label="上传图片" >
                    <div class="demo-upload-list" v-for="(item, index) in uploadList" :key="index">
                        <img :src="item.path">
                        <div class="demo-upload-list-cover">
                            <Icon type="ios-eye-outline" @click.native="handleView(item)"></Icon>
                        </div>
                    </div>
                </FormItem>
                <FormItem label="经办人" >

                    <Select v-model="submitForm.handler_id" placeholder="请选择经办人" >
                        <Option v-for="(d, i) in personList" :key="i"  :value="d.id">{{d.nickname}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="备注" prop="remarks">
                    <Input type="textarea" v-model="submitForm.remarks" placeholder="请输入备注">
                    </Input>
                </FormItem>
                <FormItem label="失败原因" prop="reason">
                    <Input type="textarea" v-model="submitForm.reason" >
                    </Input>
                </FormItem>

            </Form>
        </Modal>
        <Modal title="预览" v-model="visible">
            <img :src="imgSrc" v-if="visible" style="width: 100%">
        </Modal>
    </div>
</template>
<script>
    var  that;
    import {entry_listApi, entry_imageApi, item_selectApi, person_selectApi, export_infoApi} from '@/api/customer';
    import {tablePage} from '@/components/iview';
    import { itemsApi} from '@/api/common';
    import config from '@/config';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                userInfo:{},
                allitem:[],
                itemList:[],
                personList:[],
                uploadList:[],
                imgSrc: '',
                visible: false,
                submitForm:{
                    money:"",
                    member_id:"",
                    shopdate:"",
                    channels_id:"",
                    other_money:0,
                    coupon_money:0,
                    remarks:"",
                    defaultBase64:[],
                    formDynamic:  [
                        {
                            value: 0,
                            id:0
                        }
                    ]
                },
                exportForm:{
                    date:""
                },
                formInline:{
                    name:""
                },
                show_edit:false,
                show_title:"查看",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                        title: '#',
                        type: 'index',
                        align:'center',
                        width: 80
                    },{
                        title: '会员名称',
                        key: 'member_name',
                        width: 130,
                        align:'center'
                    },
                        {
                            title: '上级',
                            align:'center',
                            width: 130,
                            key: 'up_member_name'
                        },
                        {
                            title: '服务商',
                            align:'center',
                            width: 150,
                            key: 'channel_name'
                        },

                        {
                            title: '优惠金额',
                            align:'center',
                            width: 130,
                            key: 'coupon_money'
                        },
                        {
                            title: '其他金额',
                            align:'center',
                            width: 130,
                            key: 'other_money'
                        },
                        {
                            title: '消费金额',
                            align:'center',
                            width: 130,
                            key: 'money'
                        },
                        {
                            title: '项目',
                            width: 190,
                            align:'center',
                            key:'id',
                            render: (h, params) => {
                                let items =  params.row.items;
                                let name = "";
                                if (items != "") {
                                    let items_arr = items.split(",");
                                    for(let i in  this.allitem) {
                                        for(let j in items_arr) {
                                            if ( this.allitem[i].id == items_arr[j] ){
                                                name += this.allitem[i].name + "、  ";
                                            }
                                        }
                                    }
                                }

                                let form_dynamic =  params.row.form_dynamic;
                                if (form_dynamic != "") {
                                    name = "";
                                    let form_dynamic_arr = JSON.parse(form_dynamic);
                                    for(let i in  this.allitem) {
                                        for(let j in form_dynamic_arr) {
                                            if ( this.allitem[i].id == form_dynamic_arr[j].id ){
                                                name += this.allitem[i].name + "x" +  form_dynamic_arr[j].value + "、  ";
                                            }
                                        }
                                    }
                                }

                                if (name == "" ) {
                                    name = "其他项目";
                                }
                                return h('div', [
                                    h('textarea', {
                                        style: {
                                            margin: '5px'
                                        }
                                    }, name)
                                ]);
                            }
                        },
                        {
                            title: '消费时间',
                            align:'center',
                            width: 180,
                            key: 'shopdate'
                        },
                        {
                            title: '经办人',
                            width: 100,
                            align:'center',
                            key: 'handler_name'
                        },
                        {
                            title: '状态',
                            align:'center',
                            width: 120,
                            key:'type',
                            render: (h, params) => {
                                let name = "未知",type_s = "dashed";
                                if (params.row.status == 0){
                                    type_s = "dashed";
                                    name = "待审核"
                                } else if (params.row.status == 1){
                                    type_s = "success";
                                    name = "审核成功"
                                } else if (params.row.status == 2){
                                    type_s = "error";
                                    name = "审核失败"
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: type_s,
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, name)
                                ]);
                            }
                        },
                        {
                            title: '备注',
                            align:'center',
                            minWidth: 300,
                            key: 'remarks'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            fixed: 'right',
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.edit(params.row);
                                            }
                                        }
                                    }, '查看')
                                ]);
                            }
                        }
                    ],
                    data: [],
                }

            }
        },
        mounted(){
            this.userInfo = this.$store.state.userInfo;
            that = this;
            this.loadList();
            this.getItemList();
            this.getPersonList();
            this.getAllItem();
        },
        methods: {
            getAllItem(){
                itemsApi().then(res=>{
                    this.allitem = res.data;
                })
            },
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                this.formInline.type = 2;
                entry_listApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            },
            handleView (item) {
                this.imgSrc = item.path;
                this.visible = true;
            },
            handleViews () {
                this.imgSrc = this.submitForm.base64Img;
                this.visible = true;
            },
            edit(p){
                this.show_edit = true;
                this.show_title = "查看";
                this.submitForm = {
                    money:p.money,
                    member_name:p.member_name,
                    shopdate:p.shopdate,
                    channel_name:p.channel_name,
                    remarks:p.remarks,
                    items:p.items,
                    other_money:p.other_money,
                    coupon_money:p.coupon_money,
                    handler_id:p.handler_id,
                    defaultBase64:[],
                    formDynamic:p.form_dynamic,
                    reason:p.reason
                }
                if (this.submitForm.items != "") {
                    this.submitForm.items = this.submitForm.items.split(',')
                    for (let i in this.submitForm.items) {
                        this.submitForm.items[i] =  parseInt(this.submitForm.items[i]);
                    }
                }
                if (this.submitForm.formDynamic != "") {
                    this.submitForm.formDynamic = JSON.parse(this.submitForm.formDynamic);
                }
                entry_imageApi({id:p.id}).then(res=>{
                    this.uploadList = res.data
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
                if (p.base64_img_id  != "") {
                    this.submitForm.defaultBase64 = p.images;
                }
            },
            getItemList(){
                item_selectApi().then(res=>{
                    this.itemList = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },

            preview(e){
                console.log("--------------------预览图片---------------------");
                let img = "";
                if (e.response != undefined) {
                    img = e.response.data.url;
                } else {
                    img = e.url;
                }
                let content = "<img src='"+img+"'  style='width: 100%'>";
                this.$Modal.info({
                    title: "预览",
                    content: content
                });
            },
            getPersonList(){
                person_selectApi().then(res=>{
                    this.personList = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            changedate(e){
                this.exportForm.date = e;
            },
            importXls(){
                export_infoApi(this.exportForm).then(res=>{
                    window.open(res.data)
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            }
        }
    }
</script>
<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
