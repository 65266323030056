<template>
   <div id="app" class="layout">
        <router-view/>
        
    </div>
</template>

<script>
 
export default {
  name: 'App',
  data(){
      return{
      }
  },
  mounted(){
  },
  methods:{

  }
}
</script>
