export default{
    // title: 'sss',
    title: '纳米虫NMC',
    // apiUrl: 'http://abc.sqcloud168.com/api',
    // apiUrl: 'http://lin.com/api',
    // uploadUrl:  'http://abc.sqcloud168.com/api/upload',
    apiUrl: `${location.origin}`+'/api',
    uploadUrl:  `${location.origin}`+'/api/upload',
    QQMapKey:"7IBBZ-WB333-4JW3A-3YIUY-WYWCF-7ABY3"
}
