<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入用户名称或电话">
                </Input>
            </FormItem>
          <FormItem prop="name">
            <DatePicker type="daterange"  format="yyyy-MM-dd" @on-change="changedate"   placement="bottom-start" placeholder="选择日期" style="width: 200px"></DatePicker>
          </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
            </FormItem>
        </Form>

         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>



    </div>
</template>
<script>
import {money_list} from '@/api/customer';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                submitForm:{
                    sure_time:""
                },
                formInline:{
                  name:""
                },
                book_id:"",
                book_status:1,
                refuse_reason:"",
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                      title: '#',
                      type: 'index',
                      align:'center',
                      width: 80
                    },{
                      title: '用户',
                      key: 'name',
                      align:'center'
                    },{
                      title: '手机号',
                      key: 'phone',
                      align:'center'
                    },
                        {
                            title: '消费金额',
                            align:'center',
                            key: 'money'
                        },
                        {
                            title: '消费时间',
                            align:'center',
                            key: 'ctime'
                        }
                    ],
                    data: []
                }

            }
        },
        mounted(){
            this.loadList();

        },
        methods: {

          changedate(e){
            this.formInline.date = e;
          },
            loadList(){
              money_list({limit:this.main_table.limit, page:this.main_table.current, date: this.formInline.date, name : this.formInline.name}).then(res=>{
                    this.main_table.data = res.data.list;
                    this.main_table.count = res.data.count;
                }).catch(res=>{
                  console.log(res)
                    this.$Message.error(res.msg);
                })
            },

            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            }
        }
    }
</script>
