<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入用户名称或电话">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
            </FormItem>
        </Form>

         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>



    </div>
</template>
<script>
import {book_ListApi, book_StatustApi} from '@/api/book';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                submitForm:{
                    sure_time:""
                },
                formInline:{
                  name:""
                },
                book_id:"",
                book_status:1,
                refuse_reason:"",
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '用户',
                            key: 'member_name',
                            align:'center'
                        },
                        {
                            title: '预约电话',
                            align:'center',
                            key: 'member_phone'
                        },
                        {
                            title: '预约地点',
                            align:'center',
                            key: 'place_name'
                        },
                        {
                            title: '预约日期',
                            align:'center',
                            key: 'book_date'
                        },
                        {
                            title: '预约时间',
                            align:'center',
                            key: 'book_time'
                        },
                        {
                            title: '到达时间',
                            align:'center',
                            key: 'sure_time'
                        },
                        {
                            title: '预约人员',
                            align:'center',
                            key: 'doctor_name'
                        },
                        {
                            title: '状态',
                            align:'center',
                            key: 'status',
                            render: (h, params) => {
                                let status_text = "待处理",
                                 status_type = "dashed";
                                switch(params.row.status){
                                    case 0:
                                        status_text = "待处理";
                                        break;
                                    case 1:
                                        status_type = "success";
                                        status_text = "预约成功";
                                        break;
                                    case 2:
                                        status_type = "error";
                                        status_text = "预约失败";
                                        break;
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: status_type,
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                    }, status_text)
                                ]);
                            }
                        },
                        {
                            title: '拒绝原因',
                            key: 'reason',
                            width: 200,
                            align: 'center',
                            render: (h, params) => {

                                return h('div', [
                                    h('textarea', {
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, params.row.reason)
                                ]);
                            }
                        }
                    ],
                    data: []
                }

            }
        },
        mounted(){
            this.loadList();

        },
        methods: {
            loadList(){
                book_ListApi({limit:this.main_table.limit, page:this.main_table.current, type:2, name : this.formInline.name}).then(res=>{
                    this.main_table.data = res.data.list;
                    this.main_table.count = res.data.count;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            changeDate(e) {
                this.submitForm.sure_time = e;
            },
            show (e) {
                this.show_edit = true;
                this.book_id = e.id;
            },
            cancel(){
                this.show_edit = false;
            },
            save(){
                this.book_status = 1;
                this.modifyStatus();
            },
            remove (e) {
                this.book_id = e.id;
                this.$Modal.confirm({
                    title: '提示',
                    render: (h) => {
                        return h('div', [
                            h('div', {
                                style:"margin:10px 0"
                            }, '是否拒绝此次提现'),
                            h('Input', {
                                props: {
                                    autofocus: true,
                                    placeholder: "请输入拒绝原因"
                                },
                                on: {
                                    input: (val) => {
                                        this.refuse_reason = val;
                                    }
                                }
                            })
                        ]);
                    },
                    onOk: () => {
                        this.book_status = 2;
                        this.modifyStatus();
                    },
                    onCancel: () => {
                    }
                });
            },

            modifyStatus(){
                book_StatustApi({id:this.book_id, status:this.book_status, reason:this.refuse_reason, sure_time:this.submitForm.sure_time}).then(res=>{
                    this.$Message.success(res.msg);
                    this.show_edit = false;
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            }
        }
    }
</script>
