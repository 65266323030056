<template>
    <div>
        <Table border :columns="columns" :data="data"  ></Table>
        <br/>
        <Page :total="count" :page-size="limit" :current="current" @on-change="changePage" show-total  show-elevator style="text-align:right" />
    </div>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    props: {
        columns: {
            type: Array
        },
        data: {
            type:Array
        },
        count: {
            type: Number,
            default: 0
        },
        limit: {
            type: Number,
            default: 1
        },
        current: {
            type: Number,
            default: 1
        }

    },
    methods:{
        changePage(index){
            this.$emit('switchPage', index)
        }
    }
}
</script>