/** 系统设置路由 */
export default{
    name: 'system',
    path: 'system',
    meta: { title: '系统设置' },
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'system-wx',
            path: 'wx',
            meta: { title: '微信公众号' },
            component: ()=>import('@/pages/system/wx.vue')
        }
    ]
}
