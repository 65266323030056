/**人员管理路由 */
export default {
    name: 'person',
    path: 'person',
    meta: { title: '人员管理' },
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'person-channel',
            path: 'channel',
            meta: { title: '服务商' },
            component: ()=>import('@/pages/person/channel.vue')
        },
        {
            name: 'person-yuangong',
            path: 'yuangong',
            meta: { title: '员工' },
            component: ()=>import('@/pages/person/yuangong.vue')
        }
    ]
}

