<template>
    <div>
        <Divider orientation="left">微信入口</Divider>
        <div>
            微信入口：<span>{{wx_url}}</span>
        </div>
          <Form ref="formInline" :model="formInline" :label-width="100" >
              <Divider orientation="left">分销设置</Divider>
            <FormItem prop="one" label="一级比例">
                <Input type="text" v-model="formInline.one" placeholder="请输入一级分销，例：0.15 为15%" style="width:300px">
                </Input>
                <span class="tips-class">自己消费返利</span>
            </FormItem>
              <FormItem prop="two" label="二级比例">
                  <Input type="text" v-model="formInline.two" placeholder="请输入二级分销，例：0.15 为15%" style="width:300px">
                  </Input>
                  <span class="tips-class">上级返利</span>
              </FormItem>
              <FormItem prop="three" label="三级比例">
                  <Input type="text" v-model="formInline.three" placeholder="请输入三级分销，例：0.15 为15%" style="width:300px">
                  </Input>
                  <span class="tips-class">上上级返利</span>
              </FormItem>
              <Divider orientation="left">手机端</Divider>
              <FormItem prop="toUrl" label="登录后跳转">
                  <Input type="text" v-model="formInline.toUrl" placeholder="请输入登录后跳转地址" style="width:500px">
                  </Input>
              </FormItem>
              <FormItem prop="toUrl" label="会员卡">
                  <Upload

                          :default-file-list="defalut_imgs"
                          ref="upload"
                          :format="['jpg','jpeg','png']"
                          :max-size="2048"
                          :headers="header"
                          :on-remove="hanlderemove"
                          :on-format-error="handleFormatError"
                          :on-success="uploadImgSuccess"
                          :on-progress="handleProgress"
                          :on-preview="handlePreview"
                          :before-upload="handleBefore"
                          :action="baseURL"
                  >
                      <Button icon="ios-cloud-upload-outline">请选择图片（310*160）</Button>
                  </Upload>
              </FormItem>
              <Divider orientation="left">微信配置</Divider>
              <FormItem prop="appid" label="appid">
                  <Input type="text" v-model="formInline.appid" placeholder="请输入微信appid" style="width:300px">
                  </Input>
              </FormItem>
              <FormItem prop="appsecret" label="appsecret">
                  <Input type="text" v-model="formInline.appsecret" placeholder="请输入微信appsecret" style="width:300px">
                  </Input>
              </FormItem>
              <FormItem prop="neibu_tpl" label="内部人员通知">
                  <Input type="text" v-model="formInline.neibu_tpl" placeholder="请输入内部人员通知模板" style="width:300px">
                  </Input>
              </FormItem>
              <FormItem prop="success_tpl" label="成功通知">
                  <Input type="text" v-model="formInline.success_tpl" placeholder="请输入成功通知模板" style="width:300px">
                  </Input>
              </FormItem>
              <FormItem prop="fail_tpl" label="失败通知">
                  <Input type="text" v-model="formInline.fail_tpl" placeholder="请输入失败通知模板" style="width:300px">
                  </Input>
              </FormItem>
            <FormItem>
                <Button type="error" @click="save()" style="margin-left:10px" >保存</Button>
            </FormItem>
        </Form>
    </div>
</template>
<script>
    import config from '@/config';
    import {getCookies} from '@/utils/helper'
    import {share_Api, get_share_Api, delImg} from '@/api/login';
    export default {
        data () {
            return {

                header:{'authori-zation':getCookies('token')},
                baseURL: config.apiUrl + '/upload',
                defalut_imgs:[],
                formInline:{
                    one:"",
                    two:"",
                    three:"",
                    toUrl:"",
                    imgs_id:0,
                    imgs_url:"",
                    appid:"",
                    appsecret:"",
                    neibu_tpl:"",
                    fail_tpl:"",
                    success_tpl:"",
                },
                wx_url:""
            }
        },
        mounted(){
            this.get_api();
            let admin_id = this.$store.state.userInfo.pid == 0 ? this.$store.state.userInfo.uid : this.$store.state.userInfo.pid;
            this.wx_url = location.origin+"/h5?id="+admin_id
        },
        methods: {
            get_api(){
                get_share_Api().then(res=>{
                    this.formInline = res.data
                  if (res.data.imgs_url != "" && res.data.imgs_url!=undefined && res.data.imgs_url!=null) {
                    this.defalut_imgs = [
                      {name:res.data.imgs_url,path:res.data.imgs_url}
                    ]
                  }

                })
            },
            save(){
                share_Api(this.formInline).then(res=>{
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            //文件上传类型错误
            handleFormatError () {
                this.$Message.warning('暂不支持上传此格式');
            },
            //编辑图片上传图片成功
            uploadImgSuccess(e){
                this.formInline.imgs_url = e.data.url
                this.formInline.imgs_id = e.data.id
                this.$Message.destroy()
                this.$Message.success(e.msg);
            },
            // 删除图片
            hanlderemove(){
                delImg({id:this.formInline.imgs_id}).then(res=>{
                    this.formInline.imgs_url = ""
                    this.formInline.imgs_id = 0
                    this.$Message.success(res.msg);
                }).catch(err=>{
                    this.$Message.error(res.msg);
                })
            },
            handlePreview(){
                this.$Modal.confirm({
                    title: '预览',
                    content: '<img style="width:500px;height:500px" src="'+this.formInline.imgs_url+'">',
                    width:600
                });
            },
            handleBefore(){
                if(this.formInline.imgs_id != undefined &&  this.formInline.imgs_id != "" &&  this.formInline.imgs_id != 0) {
                    this.$Message.error("请先删除图片，再进行上传");
                    return false
                }
            },
            // 文件上传时
            handleProgress(){
                this.$Message.loading({
                    content: '上传中...',
                    duration: 0
                });
            }
        }
    }
</script>
<style scoped>
    .tips-class{
        margin-left:10px;
    }
</style>
