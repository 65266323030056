import Vue from "vue";
import config from '@/config';
import VueRouter from "vue-router";
import mainLayout from "@/components/mainLayout"
import index from "./modules/index"
import customer from "./modules/customer"
import yuyue from "./modules/yuyue"
import orders from "./modules/orders"
import system from "./modules/system"
import book from "./modules/book"
import finace from "./modules/finace"
import person from "./modules/person"
import modify from "./modules/modify"
import share from "./modules/share"
import money from "./modules/money"
import {getCookies, setIco} from "@/utils/helper";
Vue.use(VueRouter);

const router = new VueRouter({
    routes:[
        {
            path:'/',
            meta: {title: '首页'},
            component: mainLayout,
            children: [
                ...index,
                customer,
                orders,
                yuyue,
                finace,
                person,
                system,
                modify,
                book,
                share,
                money
            ]
        },
        {
            name: '登录',
            path: '/login',
            component: ()=>import("@/pages/login/login.vue")
        },
        {
            name: '跳转',
            path: '/jump',
            component: ()=>import("@/pages/login/jump.vue")
        }
    ]
})

router.beforeEach((to, from, next) => {
    let infos = getCookies(location.hostname)
    let title = config.title;
    if (infos) {
        title =  infos.title ? infos.title : config.title;
        if (infos.ico != "") {
            setIco(infos.ico)
        }

    }
    window.document.title = title
    next();
})
export default router;
