import request from '@/utils/request'

export function delImgApi (data) {
    return request({
        url: 'del_img',
        method: 'post',
        data
    })
}

export function itemsApi (data) {
    return request({
        url: 'all_item',
        method: 'post',
        data
    })
}
