/**订单路由 */
export default{
    name: 'orders',
    path: 'orders',
    meta: { title: '入账管理' },
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'orders-bookkeeping',
            path: 'bookkeeping',
            meta: { title: '会员入账' },
            component: ()=>import('@/pages/orders/bookkeeping.vue')
        },
        {
            name: 'orders-examine',
            path: 'examine',
            meta: { title: '入账审核' },
            component: ()=>import('@/pages/orders/examine.vue')
        },
        {
            name: 'orders-history',
            path: 'history',
            meta: { title: '历史入账' },
            component: ()=>import('@/pages/orders/history.vue')
        },
        {
            name: 'orders-entryitem',
            path: 'entryitem',
            meta: { title: '入账项目' },
            component: ()=>import('@/pages/orders/entryitem.vue')
        }
    ]
}
