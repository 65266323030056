/** 充值提现的路由 */
export default{
    name: 'yuyue',
    path: 'yuyue',
    meta: {title: '预约管理'},
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'yuyue-manager',
            path: 'manager',
            meta: { title: '预约设置' },
            component: ()=>import('@/pages/yuyue/index.vue')
        },
        {
            name: 'yuyue-doctor',
            path: 'doctor',
            meta: { title: '人员列表' },
            component: ()=>import('@/pages/yuyue/doctor.vue')
        },
        {
            name: 'yuyue-place',
            path: 'place',
            meta: { title: '地点管理' },
            component: ()=>import('@/pages/yuyue/place.vue')
        }
    ]
}
