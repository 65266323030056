<template>
    <div>
        <div>
            <Upload
                    :headers="{'authori-zation':auth_token}"
                    ref="upload"
                    :show-upload-list="false"
                    :default-file-list="defaultList"
                    :on-success="handleSuccess"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :on-format-error="handleFormatError"
                    :on-exceeded-size="handleMaxSize"
                    multiple
                    type="drag"
                    :action="uploadUrl"
                    style="display: inline-block;width:58px;">
                <div style="width: 58px;height:58px;line-height: 58px;">
                    <Icon type="ios-camera" size="20"></Icon>
                </div>
            </Upload>
        </div>
        <div>
            <div class="demo-upload-list" v-for="item in uploadList">
                <template v-if="item.status === 'finished'">
                    <img :src="item.path">
                    <div class="demo-upload-list-cover">
                        <Icon type="ios-eye-outline" @click.native="handleView(item)"></Icon>
                        <Icon type="ios-trash-outline" @click.native="handleRemove(item)"></Icon>
                    </div>
                </template>
                <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                </template>
            </div>
        </div>


        <Modal title="预览" v-model="visible">
            <img :src="imgSrc" v-if="visible" style="width: 100%">
        </Modal>
    </div>

</template>

<script>
    import {getCookies} from "@/utils/helper";
    export default {
        data () {
            return {
                auth_token: getCookies('token'),
                imgSrc: '',
                visible: false,
                uploadList: []
            }
        },
        props: {
            defaultList: {
                type: Array
            },
            uploadUrl: {
                type:String
            }

        },
        watch:{
            defaultList(newVal,oldVal){
                this.uploadList = newVal;
            }
        },
        methods: {
            handleView (item) {
                this.imgSrc = item.path;
                this.visible = true;
            },
            handleRemove (file) {
                const fileList = this.$refs.upload.fileList;
                this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
                this.uploadList.splice(this.uploadList.indexOf(file), 1);
                this.$emit("delFile", file.id)
            },
            handleSuccess (res, file) {
                file.path = res.data.url;
                file.id = res.data.id;
                let img = {"id":file.id, "path":file.path, "status":"finished"}
                this.$refs.upload.fileList.unshift(img)
                this.uploadList.unshift(img)
                this.$emit('uploadFile', this.$refs.upload.fileList);
            },
            handleFormatError (file) {
                this.$Notice.warning({
                    title: '文件格式错误',
                    desc: '文件格式错误, 请选择jpg 或 png.'
                });
            },
            handleMaxSize (file) {
                this.$Notice.warning({
                    title: '文件超限',
                    desc: '文件太大, 超过了 2M.'
                });
            }
        },
        mounted () {
            this.uploadList = this.$refs.upload.fileList;
            console.log(this.uploadList)
        }
    }
</script>
<style>
    .demo-upload-list{
        display: inline-block;
        width: 60px;
        height: 60px;
        text-align: center;
        line-height: 60px;
        border: 1px solid transparent;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
        margin-right: 4px;
    }
    .demo-upload-list img{
        width: 100%;
        height: 100%;
    }
    .demo-upload-list-cover{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,.6);
    }
    .demo-upload-list:hover .demo-upload-list-cover{
        display: block;
    }
    .demo-upload-list-cover i{
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        margin: 0 2px;
    }
</style>
