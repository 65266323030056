<template>
    <div>
        <!--<H1>欢迎使用会员系统</H1>-->
        <Row>
            <Col span="24">
                <div >
                    <Form ref="formInline" :model="formInline"  inline :label-width="120" >

                        <FormItem label="所属服务商" prop="channels_id" v-if="userInfo.type != 2">
                            <Select v-model="submitForm.channels_id" filterable placeholder="请选择服务商" @on-change="changeChannels">
                                <Option v-for="(d, i) in selects.channels"  :key="i"  :value="d.id">{{d.nickname}}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="会员" prop="member_id">
                            <Select v-model="submitForm.member_id" placeholder="请选择会员" filterable >
                                <Option v-for="(d, i) in selects.members" :key="i"  :value="d.id">{{d.name}}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="选择日期" prop="date">
                            <DatePicker type="daterange"   format="yyyy-MM-dd" @on-change="changeDate"  placement="bottom-start" placeholder="选择日期" style="width: 200px"></DatePicker>
                        </FormItem>

                        <FormItem>
                            <Button type="primary" @click="getMoney()" >查询</Button>
                        </FormItem>
                    </Form>
                </div>
            </Col>
        </Row><br/>
        <Row>
            <Col span="4"  offset="1">
                <Card>
                    <p slot="title">服务商会员数</p>
                    <p>{{infos.member_num}}</p>
                </Card>
            </Col>
            <Col span="4" offset="1">
                <Card >
                    <p slot="title">会员消费金额</p>
                    <p>{{infos.money}}</p>
                </Card>
            </Col>
        </Row>

    </div>
</template>
<script>
    var that;
    import { member_selectApi, member_infosApi} from '@/api/customer';

    export default {
    components:{
    },
    data(){
        return{
            userInfo:{},
            submitForm:{
                channels_id:-1,
                member_id:-1,
                date:[]
            },
            infos:{
                member_num:0,
                money:0
            },
            select_channels_id:-1,
            formInline:{},
            selects:{
                members:[],
                channels:[],
                alls:[]
            }
        }
    },
    mounted(){
        this.userInfo = this.$store.state.userInfo;
        that = this;
        this.getPerson();
        this.getMoney();
    },
    methods:{
        changeChannels(e){
            this.select_channels_id = e;
            if (this.select_channels_id != '-1' ) {
                this.selects.members = [];
                this.selects.alls.forEach(function(item){
                    if (item.id == '-1' || that.select_channels_id == item.channels_id) {
                        that.selects.members.push(item);
                    }
                })
            } else {
                this.selects.members = this.selects.alls;
            }
            // this.infos.member_num = this.selects.members.length - 1;
            this.submitForm.member_id = -1;
        },
        getPerson(){
            member_selectApi({type:0}).then(res=>{
                this.selects.members = res.data.member;
                this.selects.alls = res.data.member;
                this.selects.channels = res.data.channel;
                this.infos.member_num = this.selects.alls.length - 1;
            }).catch(res=>{
                this.$Message.error(res.msg);
            })
        },
        getMoney(){
            member_infosApi(this.submitForm).then(res=>{
                this.infos.money = res.data
            }).catch(res=>{
                this.$Message.error(res.msg);
            })
        },
        changeDate(e){
            this.submitForm.date = e;
        }
    }
}
</script>
<style>
    h1{
        text-align:center;
        margin-top: 100px;
    }
</style>
