<template>
    <div>

        <Button type="success" @click="add()" style="margin-bottom:10px" >新增</Button>
        <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>



        <Modal
                v-model="show_edit" :mask-closable="false" footer-hide
                :title="show_title">
            <Form  :model="submitForm"  :label-width="80">
                <FormItem label="员工名称" prop="name">
                    <Input type="text" v-model="submitForm.nickname" placeholder="请输入员工名称">
                    </Input>
                </FormItem>
                <FormItem label="联系电话" prop="phone">
                    <Input type="text" v-model="submitForm.phone" placeholder="请输入联系电话">
                    </Input>
                </FormItem>

                <FormItem label="员工身份" prop="type_id">
                    <Select v-model="submitForm.type_id" placeholder="请选择身份" filterable>
                        <Option v-for="(d, i) in type_list" :key="i"  :value="d.id">{{d.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="登录密码" prop="password">
                    <Input type="password" v-model="submitForm.password" placeholder="请输入登录密码">
                    </Input>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="save()" style="margin-left:10px" >保存</Button>
                    <Button type="error" @click="cancel()" style="margin-left:10px" >取消</Button>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>
<script>
    import {person_listApi, person_addApi, person_delApi} from '@/api/customer';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                submitForm:{
                    nickname:"",
                    phone:"",
                    password:"",
                    type_id:3
                },
                formInline:{},
                show_edit:false,
                show_title:"新增",
                type_list:[
                    {id:3, name:'员工'},
                    {id:4, name:'财务'},
                  {id:5, name:'客服'},
                  {id:6, name:'股东'}
                ],
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                        title: '#',
                        type: 'index',
                        align:'center',
                        width: 50
                    },{
                        title: '员工名称',
                        key: 'nickname',
                        width: 150,
                        align:'center'
                    },
                        {
                            title: '联系电话',
                            align:'center',
                            key: 'phone'
                        },
                        {
                          title: '员工身份',
                          align:'center',
                          key:'type',
                            render: (h, params) => {
                              let name = "未知";
                                if (params.row.type == 3){
                                    name = "普通员工"
                                } else if (params.row.type == 4){
                                    name = "财务"
                                } else if (params.row.type == 5){
                                    name = "客服"
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, name)
                                ]);
                            }
                        },
                        {
                            title: '注册时间',
                            align:'center',
                            key: 'reg_time'
                        },
                        {
                            title: '上次登录时间',
                            align:'center',
                            key: 'last_login_time'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.edit(params.row);
                                            }
                                        }
                                    }, '编辑'),
                                    h('Button', {
                                        props: {
                                            type: 'error',
                                            size: 'small'
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row)
                                            }
                                        }
                                    }, '删除')
                                ]);
                            }
                        }
                    ],
                    data: []
                }
            }
        },
        mounted(){

            this.loadList();
        },
        methods: {
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                this.formInline.type = 3;
                person_listApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            },
            cancel(){
                this.show_edit = false;
            },
            edit(p){
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = {
                    nickname:p.nickname,
                    phone:p.phone,
                    password:"",
                    type_id:p.type,
                    id:p.id
                }
            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    nickname:"",
                    phone:"",
                    type_id:"",
                    password:""

                }
            },
            save(){
                if(this.submitForm.nickname == "") {
                    this.$Message.error("账户不能为空");return;
                }
                if(this.submitForm.phone == "") {
                    this.$Message.error("手机号不能为空");return;
                }
                person_addApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            remove (params) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该员工？</p>',
                    onOk: () => {
                        this.delMember(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            delMember(id){
                person_delApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
        }
    }
</script>
