<template>
    <div>

        <Button type="success" @click="add()" style="margin-bottom:10px" >新增</Button>
        <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>




        <Modal
                v-model="show_edit" :mask-closable="false" footer-hide
                :title="show_title">
            <Form  :model="submitForm"  :label-width="100">
                <FormItem label="所属服务商" prop="channels_id" > <!-- @on-change="changeChannels"  :disabled="userInfo.type == 2" -->
                    <Select v-model="submitForm.channels_id" filterable placeholder="请选择服务商"  disabled >
                        <Option v-for="(d, i) in selects.channels"  :key="i"  :value="d.id">{{d.nickname}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="会员" prop="member_id">
                    <Select v-model="submitForm.member_id"  @on-change="changeMember" placeholder="请选择会员" filterable >
                        <Option v-for="(d, i) in selects.members" :key="i"  :value="d.id">{{d.name}}-{{d.phone}}</Option>
                    </Select>
                </FormItem>

                <FormItem label="上传消费凭证" prop="base64Img"  v-if="userInfo.type != 2" >
                    <Upload
                            ref="uploadimg"
                            multiple
                            :headers="{'authori-zation':auth_token}"
                            :default-file-list="submitForm.defaultBase64"
                            :action="upload_url"
                            :on-success="successUpload"
                            :on-preview="preview"
                            :on-remove="removeImg"
                            :before-upload="beforUpload"
                    >
                        <Button icon="ios-cloud-upload-outline">请选择消费凭证</Button>
                    </Upload>
                </FormItem>
                <FormItem label="选择时间" prop="shopdate">
                    <DatePicker type="datetime" format="yyyy-MM-dd HH:mm" v-model="submitForm.shopdate" placeholder="请选择消费时间"   @on-change="changeDate" style="width: 200px"></DatePicker>
                </FormItem>
                <!--<FormItem label="消费项目" >-->
                    <!--<Select v-model="submitForm.items" multiple @on-change="changeMoney"  filterable :disabled="userInfo.type == 2" >-->
                        <!--<Option v-for="item in itemList"   :value="item.id" :key="item.id">{{ item.name }}</Option>-->
                    <!--</Select>-->
                <!--</FormItem>-->
                <FormItem
                        v-for="(item, index) in submitForm.formDynamic"
                        :key="index"
                        label="消费项目">
                    <Row :gutter="10">
                        <Col span="14">
                            <Select v-model="item.id"  @on-change="changeMoney(1)"  filterable :disabled="userInfo.type == 2" >
                                <Option v-for="it in itemList"   :value="it.id" :key="it.id">{{ it.name }}</Option>
                            </Select>
                        </Col>
                        <Col span="4">
                            <Input type="text" @on-change="changeMoney(2)" v-model="item.value" :disabled="userInfo.type == 2" placeholder="次数"></Input>
                        </Col>
                        <Col span="2" offset="1">
                            <Button @click="handleRemove(index)">-</Button>
                        </Col>
                    </Row>
                </FormItem>

                <FormItem>
                    <Row>
                        <Col span="12">
                            <Button type="dashed" long @click="handleAdd" icon="md-add">添加项目</Button>
                        </Col>
                    </Row>
                </FormItem>
                <FormItem label="其他金额" prop="phone"   v-if="userInfo.type != 2">
                    <Input type="text" v-model="submitForm.other_money" @on-change="changeMoney(2)" placeholder="请输入其他金额">
                    </Input>
                </FormItem>
                <FormItem label="优惠金额" prop="phone"   v-if="userInfo.type != 2">
                    <Input type="text" v-model="submitForm.coupon_money"  @on-change="changeMoney(2)" placeholder="请输入优惠金额">
                    </Input>
                </FormItem>
                <FormItem label="总消费金额" prop="phone">
                    <Input type="text" v-model="submitForm.money" disabled placeholder="请输入消费金额">
                    </Input>
                </FormItem>
                <FormItem label="上传图片" >
                        <uploadList ref="children_upload" :uploadUrl="upload_url" :defaultList="defaultList"  @delFile="delImg" @uploadFile="uploadFile" ></uploadList>
                </FormItem>
                <FormItem label="经办人"  v-if="userInfo.type != 2">

                    <Select v-model="submitForm.handler_id" placeholder="请选择经办人"  :disabled="userInfo.type == 3"  >
                        <Option v-for="(d, i) in personList" :key="i"  :value="d.id">{{d.nickname}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="备注" prop="remarks">
                    <Input type="textarea" v-model="submitForm.remarks" placeholder="请输入备注">
                    </Input>
                </FormItem>

                <FormItem>
                    <Button type="primary" @click="save()" style="margin-left:10px" >保存</Button>
                    <Button type="error" @click="cancel()" style="margin-left:10px" >取消</Button>
                </FormItem>
            </Form>
        </Modal>
    </div>
</template>
<script>
    var  that;
    import {entry_listApi, entry_addApi,entry_delApi, member_selectApi, entry_imageApi, item_selectApi, person_selectApi} from '@/api/customer';
    import {delImgApi} from '@/api/common';
    import {tablePage, uploadList} from '@/components/iview';
    import config from '@/config';
    import {getCookies} from "@/utils/helper";
    export default {
        components:{
            tablePage,
            uploadList
        },
        data () {
            return {
                userInfo:{},
                auth_token: getCookies('token'),
                itemList:[],
                personList:[],
                defaultList: [],
                upload_url:config.uploadUrl,
                select_channels_id:'',
                submitForm:{
                    money:0,
                    member_id:"",
                    shopdate:"",
                    channels_id:"",
                    base64_img_id:[],
                    remarks:"",
                    other_money:0,
                    coupon_money:0,
                    formDynamic:  [
                            {
                                value: 0,
                                id:0
                            }
                        ]
                },
                selects:{
                    members:[],
                    channels:[],
                    alls:[]
                },
                formInline:{},
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                        title: '#',
                        type: 'index',
                        align:'center',
                        width: 80
                    },{
                        title: '会员名称',
                        key: 'member_name',
                        width: 130,
                        align:'center'
                    },
                        {
                            title: '服务商',
                            align:'center',
                            width: 150,
                            key: 'channel_name'
                        },
                        {
                            title: '优惠金额',
                            align:'center',
                            width: 130,
                            key: 'coupon_money'
                        },
                        {
                            title: '其他金额',
                            align:'center',
                            width: 130,
                            key: 'other_money'
                        },
                        {
                            title: '消费金额',
                            align:'center',
                            width: 130,
                            key: 'money'
                        },
                        {
                            title: '项目',
                            align:'center',
                            width: 190,
                            key:'id',
                            render: (h, params) => {
                               let items =  params.row.form_dynamic;
                                let name = "";
                                if (items != "") {
                                    let items_arr = JSON.parse(items);
                                    for(let i in  this.itemList) {
                                        for(let j in items_arr) {
                                            if ( this.itemList[i].id == items_arr[j].id ){
                                                name += this.itemList[i].name + "x" +  items_arr[j].value + "、  ";
                                            }
                                        }
                                    }
                                }

                                    if (name == "" ) {
                                        name = "其他项目";
                                    }
                                    return h('div', [
                                        h('textarea', {
                                            style: {
                                                marginRight: '5px'
                                            }
                                        }, name)
                                    ]);
                            }
                        },

                        {
                            title: '消费时间',
                            align:'center',
                            width: 180,
                            key: 'shopdate'
                        },
                        {
                            title: '经办人',
                            width: 100,
                            align:'center',
                            key: 'handler_name'
                        },
                        {
                            title: '状态',
                            align:'center',
                            width: 100,
                            key:'type',
                            render: (h, params) => {
                                let name = "未知",type_s = "dashed";
                                if (params.row.status == 0){
                                    type_s = "dashed";
                                    name = "待审核"
                                } else if (params.row.status == 1){
                                    type_s = "success";
                                    name = "审核成功"
                                } else if (params.row.status == 2){
                                    type_s = "error";
                                    name = "审核失败"
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: type_s,
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, name)
                                ]);
                            }
                        },
                        {
                            title: '备注',
                            align:'center',
                            minWidth: 300,
                            key: 'remarks'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            fixed: 'right',
                            render: (h, params) => {
                                let buttonArr = [];
                                let editBtn = h('Button', {
                                    props: {
                                        type: 'primary',
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.edit(params.row);
                                        }
                                    }
                                }, '编辑'),
                                delBtn = h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small'
                                    },
                                    on: {
                                        click: () => {
                                            this.remove(params.row)
                                        }
                                    }
                                }, '删除');
                                buttonArr.push(editBtn);
                                buttonArr.push(delBtn);
                                return h('div', [
                                    buttonArr
                                ]);
                            }
                        }
                    ],
                    data: []
                }

            }
        },
        mounted(){
            this.userInfo = this.$store.state.userInfo;
            that = this;
            this.loadList();
            this.getPerson();
            this.getItemList();
            this.getPersonList();
        },
        methods: {
            changeDate(e){
                this.submitForm.shopdate = e;
            },
            handleAdd () {
                this.submitForm.formDynamic.push({
                    value: 0,
                    id:0
                });
            },
            handleRemove (index) {
                this.submitForm.formDynamic.splice(index, 1);
                this.changeMoney(2);
            },
            changeMember(e){
                for(var i in this.selects.members) {
                    if(this.selects.members[i].id == e) {
                        this.submitForm.channels_id = this.selects.members[i].channels_id
                    }
                }
            },
            handler(){
                if (this.userInfo.type == 3) {
                    for(var i in this.personList) {
                        if (this.personList[i].id == this.userInfo.uid) {
                            this.submitForm.handler_id = parseInt(this.userInfo.uid);
                        }
                    }
                }
            },
            chanels_handler(){
                if (this.userInfo.type == 2) {
                    this.submitForm.items = [31];
                    this.submitForm.money = 0;
                    for(var i in this.selects.channels) {
                        if (this.selects.channels[i].id == this.userInfo.uid) {
                            this.submitForm.channels_id = parseInt(this.userInfo.uid);
                        }
                    }
                }

            },
            preview(e){
                console.log("--------------------预览图片---------------------");
                let img = "";
                if (e.response != undefined) {
                    img = e.response.data.url;
                } else {
                    img = e.url;
                }
                let content = "<img src='"+img+"'  style='width: 100%'>";
                this.$Modal.info({
                    title: "预览",
                    content: content
                });
            },
            removeImg(e){
                console.log("--------------------删除文件---------------------");
                let id = 0;
                if (e.response != undefined) {
                    id = e.response.data.id;
                } else {
                    id = e.id;
                }

                for(var i in this.submitForm.base64_img_id) {
                    if (this.submitForm.base64_img_id[i] == id ) {
                        this.submitForm.base64_img_id.splice(i, 1);
                    }
                }
                this.submitForm.base64_img_id = this.submitForm.base64_img_id == undefined ? [] : this.submitForm.base64_img_id;
                delImgApi({id:id}).then(res=>{
                    console.log(res);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            beforUpload(){

            },
            successUpload(e){
                this.submitForm.base64_img_id.push(e.data.id);
            },
            delImg(e){
                delImgApi({id:e}).then(res=>{
                    let index =  this.submitForm.files.indexOf(e);
                    if (index > -1) {
                        this.submitForm.files.splice(index, 1);
                    }
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            uploadFile(e){
                this.submitForm.files = [];
                for(var i in e) {
                    if (e[i].id != undefined){
                        this.submitForm.files.unshift(e[i].id);
                    }
                }
            },
            changeChannels(e){
                this.select_channels_id = e;
                if (this.select_channels_id !=0 ) {
                    this.selects.members = [];
                    this.selects.alls.forEach(function(item){
                        if (that.select_channels_id == item.channels_id) {
                            that.selects.members.push(item);
                        }
                    })
                } else {
                    this.selects.members = this.selects.alls;
                }
            },
            getPerson(){
                member_selectApi({type:0}).then(res=>{
                    this.selects.members = res.data.member;
                    this.selects.alls = res.data.member;
                    this.selects.channels = res.data.channel;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                this.formInline.type = 1;
                entry_listApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            },
            cancel(){
                this.show_edit = false;
            },
            edit(p){
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = {
                    money:p.money,
                    member_id:p.member_id,
                    shopdate:p.shopdate,
                    channels_id:p.channels_id,
                    base64_img_id:p.base64_img_id,
                    remarks:p.remarks,
                    items:p.items,
                    other_money:p.other_money,
                    coupon_money:p.coupon_money,
                    defaultBase64:[],
                    formDynamic:p.form_dynamic,
                    id:p.id
                }
                if (this.submitForm.formDynamic != "") {
                    this.submitForm.formDynamic = JSON.parse(this.submitForm.formDynamic);
                }
                if (this.submitForm.items != "") {
                    this.submitForm.items = this.submitForm.items.split(',')
                    for (let i in this.submitForm.items) {
                        this.submitForm.items[i] =  parseInt(this.submitForm.items[i]);
                    }
                }

                entry_imageApi({id:p.id}).then(res=>{
                    this.defaultList = res.data
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
                if (p.base64_img_id  != "") {
                    this.submitForm.defaultBase64 = p.images;
                }
            },
            add(){
                this.defaultList = [];
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    money:0,
                    member_id:"",
                    shopdate:"",
                    remarks:"",
                    other_money:0,
                    coupon_money:0,
                    channels_id:"",
                    base64_img_id:[],
                    defaultBase64:[],
                    formDynamic:  [
                        {
                            value: 0,
                            id:0
                        }
                    ]
                }


                this.handler();
                this.chanels_handler();
                this.submitForm.files = [];
            },
            save(){
                if(this.submitForm.member_id == "") {
                    this.$Message.error("请选择会员");return;
                }
                if(this.submitForm.shopdate == "") {
                    this.$Message.error("请选择日期");return;
                }
                if(this.userInfo.type != 2 &&  this.submitForm.base64_img_id.length == 0) {
                    this.$Message.error("请上传消费凭证");return;
                }
                entry_addApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            remove (params) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要取消此次入账？</p>',
                    onOk: () => {
                        this.delEntry(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            delEntry(id){
                entry_delApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            getItemList(){
                item_selectApi().then(res=>{
                    this.itemList = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            getPersonList(){
                person_selectApi().then(res=>{
                    this.personList = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },

            changeMoney(e){
                if (e == 1) {
                    for(let j in this.submitForm.formDynamic) {
                        this.submitForm.formDynamic[j].value = this.submitForm.formDynamic[j].value ? this.submitForm.formDynamic[j].value : 1
                    }
                }
                this.submitForm.items = [];
                this.submitForm.money = 0;
                for(let i in  this.itemList) {
                    for(let j in this.submitForm.formDynamic) {
                        if ( this.itemList[i].id == this.submitForm.formDynamic[j].id ){
                            this.submitForm.items.push(this.submitForm.formDynamic[j].id);
                            this.submitForm.money += this.itemList[i].money * (this.submitForm.formDynamic[j].value ? this.submitForm.formDynamic[j].value :0);
                        }
                    }
                }
                this.submitForm.money += parseFloat(this.submitForm.other_money ? this.submitForm.other_money : 0);
                this.submitForm.money += parseFloat(this.submitForm.coupon_money ? this.submitForm.coupon_money : 0);


                if (this.userInfo.type == 2) {
                    this.submitForm.money = 0;
                }
            }
        }
    }
</script>
