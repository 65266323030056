import request from '@/utils/request'
/**预约管理模块 */

// 预约设置列表
export function book_settingListApi (data) {
    return request({
        url: 'book/setting_list',
        method: 'post',
        data
    })
}
export function book_settingAddtApi (data) {
    return request({
        url: 'book/setting_add',
        method: 'post',
        data
    })
}


/**人员模块 */
// 人员列表
export function book_doctorListApi (data) {
    return request({
        url: 'book/doctor_list',
        method: 'post',
        data
    })
}
// 添加人员
export function book_addDoctorApi (data) {
    return request({
        url: 'book/doctor_add',
        method: 'post',
        data
    })
}
// 人员下拉框
export function book_doctorSelectApi (data) {
    return request({
        url: 'book/doctor_select',
        method: 'post',
        data
    })
}
// 删除人员
export function book_delDoctorApi (data) {
    return request({
        url: 'book/doctor_del',
        method: 'post',
        data
    })
}
// 地点列表
export function book_placeListApi (data) {
    return request({
        url: 'book/place_list',
        method: 'post',
        data
    })
}
// 添加地点
export function book_addPlaceApi (data) {
    return request({
        url: 'book/place_add',
        method: 'post',
        data
    })
}
// 删除地点
export function book_delPlaceApi (data) {
    return request({
        url: 'book/place_del',
        method: 'post',
        data
    })
}
// 人员下拉框
export function book_placeSelectApi (data) {
    return request({
        url: 'book/place_select',
        method: 'post',
        data
    })
}
// 提交地点坐标
export function book_updateLatlng (data) {
    return request({
        url: 'book/place_lat',
        method: 'post',
        data
    })
}
// 通过地址获取经纬度
export function book_getLatlng (data) {
    return request({
        url: 'book/place_addresss_lat',
        method: 'post',
        data
    })
}

/** 预约订单***/

export function book_ListApi (data) {
    return request({
        url: 'bookorder/list',
        method: 'post',
        data
    })
}
export function book_StatustApi (data) {
    return request({
        url: 'bookorder/status',
        method: 'post',
        data
    })
}
