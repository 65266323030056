/**预约订单路由 */
export default{
    name: 'book',
    path: 'book',
    meta: { title: '预约订单' },
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'book-index',
            path: 'index',
            meta: { title: '待处理' },
            component: ()=>import('@/pages/book/index.vue')
        },
        {
            name: 'book-history',
            path: 'history',
            meta: { title: '历史记录' },
            component: ()=>import('@/pages/book/history.vue')
        }
    ]
}
