/**财务路由 */
export default{
    name: 'finace',
    path: 'finace',
    meta: { title: '财务提现' },
    component: ()=>import('@/pages/index.vue'),
    children: [
        {
            name: 'finace-index',
            path: 'index',
            meta: { title: '提现审核' },
            component: ()=>import('@/pages/finace/index.vue')
        },
        {
            name: 'finace-history',
            path: 'history',
            meta: { title: '历史记录' },
            component: ()=>import('@/pages/finace/history.vue')
        }
    ]
}
