import VueCookies from 'vue-cookies'

// setToken
export const setCookies = (key, val, cookieExpires) => {
    VueCookies.set(key, val, { expires: cookieExpires || 1 })
}

// 获取getCookies
export const getCookies = (key) => {
    return VueCookies.get(key)
}

export const removeCookies = (key) => {
    return VueCookies.remove(key)
}

export const setIco = (link) =>{
    let favicon = document.querySelector('link[rel="icon"]');
    if (favicon !== null) {
        favicon.href = link;
    } else {
        favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.href = link;
        document.head.appendChild(favicon);
    }
}