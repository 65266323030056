<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入姓名、手机号">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
            </FormItem>
        </Form>
        <Button v-if="userInfo.type != 4 && userInfo.type != 2" type="success" @click="add()" style="margin-bottom:10px" >新增</Button>
         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>


       <Modal
            v-model="show_nums" :mask-closable="false" footer-hide :width="900"
            :title="show_nums_title">
             <tablePage :columns="nums_table.columns" :limit="nums_table.limit" :data="nums_table.data" :count="nums_table.count" :current="nums_table.current" @switchPage="switchNumsPage" ></tablePage>

        </Modal>


        <Modal
                v-model="show_money" :mask-closable="false" footer-hide :width="800"
                title="资金明细">
            <tablePage :columns="money_table.columns" :limit="money_table.limit" :data="money_table.data" :count="money_table.count" :current="money_table.current" @switchPage="switchMoneyPage" ></tablePage>

        </Modal>


        <Modal
                v-model="show_edit" :mask-closable="false" footer-hide
                :title="show_title">
            <Form  :model="submitForm"  :label-width="100">
                <FormItem label="会员名称" prop="name">
                    <Input type="text" v-model="submitForm.name" placeholder="请输入会员名称">
                    </Input>
                </FormItem>
                <FormItem label="联系电话" prop="phone">
                    <Input type="text" v-model="submitForm.phone" placeholder="请输入联系电话">
                    </Input>
                </FormItem>
<!--                <FormItem label="登录账户" prop="account">-->
<!--                    <Input type="text" v-model="submitForm.account" placeholder="请输入登录账户">-->
<!--                    </Input>-->
<!--                </FormItem>-->

                <FormItem label="上级会员" prop="up_id">
                    <Select v-model="submitForm.up_id" placeholder="请选择上级会员"  :disabled="userInfo.type == 2 || this.userInfo.type == 3">
                        <Option v-for="(d, i) in selects.members"  :key="i"  :value="d.id">{{d.name}}</Option>
                    </Select>
                </FormItem>
                <FormItem label="所属服务商" prop="channels_id">
                    <Select v-model="submitForm.channels_id" placeholder="请选择服务商"  :disabled="userInfo.type == 2 || this.userInfo.type == 3" >
                        <Option v-for="(d, i) in selects.channels"  :key="i"  :value="d.id">{{d.nickname}}</Option>
                    </Select>
                </FormItem>
<!--                <FormItem label="登录密码" prop="pwd">-->
<!--                    <Input type="text" v-model="submitForm.pwd" placeholder="请输入登录密码">-->
<!--                    </Input>-->
<!--                </FormItem>-->
                <FormItem>
                    <Button type="primary" @click="save()" style="margin-left:10px" >保存</Button>
                    <Button type="error" @click="cancel()" style="margin-left:10px" >取消</Button>
                </FormItem>
            </Form>
        </Modal>
        <Modal
                v-model="show_info" :mask-closable="false" footer-hide :width="800"
                title="入账详情" >
            <div style="height:600px;overflow-y: auto">
                <Timeline v-if="entry_data.length > 0 ">
                    <TimelineItem v-for="(item, index) in entry_data" :key="index">
                        <p class="time">{{item.shopdate}}</p>
                        <div class="content">
                            <div style="padding:5px"> <span style="font-weight: bold;margin-right:10px;">消费金额</span>￥{{item.money}} <span style="margin-left:10px" v-if="item.item_name">{{item.item_name}}</span><span style="margin-left:10px" v-else>其他项目</span></div>
                            <div style="padding:5px">
                                <span style="vertical-align: text-top;font-weight: bold;margin-right:10px;">消费凭证</span>
                                <img  v-if="item.base64_images"  v-for="(it, index) in item.base64_images" style="cursor:pointer;vertical-align: text-top;width:58px;height:58px" :src="it.url" @click="preview(it.url)" />
                            </div>
                            <div style="padding:5px">{{item.remarks}}</div>
                            <div  style="padding:5px" >
                                <img v-for="(i, index) in item.images"  :key="index" style="cursor:pointer;vertical-align: text-top;width:58px;height:58px;" :src="i.path" @click="preview(i.path)" />
                            </div>
                        </div>
                    </TimelineItem>

                </Timeline>
                <div v-else>
                    暂无数据
                </div>
            </div>

        </Modal>


        <Modal title="预览" v-model="visible">
            <img :src="imgSrc" v-if="visible" style="width: 100%">
        </Modal>
    </div>
</template>
<script>
    import {member_listApi, member_addApi, member_delApi, member_selectApi, entry_getApi, order_infoApi, member_shareApi, item_selectApi} from '@/api/customer';
    import {tablePage} from '@/components/iview';
    import { itemsApi} from '@/api/common';
    import {book_ListApi} from '@/api/book';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                itemList:[],
                userInfo:{},
                visible:false,
                imgSrc:"",
                entry_data:[],
                show_info:false,
                submitForm:{
                    name:"",
                    phone:"",
                    account:"",
                    pwd:"",
                    channels_id:"",
                    up_id:""
                },
                selects:{
                    members:[],
                    channels:[]
                },
                formInline:{
                    name:""
                },
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '#',
                            type: 'index',
                            align:'center',
                            width: 80
                        },{
                            title: '会员名称',
                            key: 'name',
                            width: 150,
                            align:'center'
                        },
                        {
                            title: '联系电话',
                            align:'center',
                            width: 150,
                            key: 'phone'
                        },
                        {
                            title: '会员余额',
                            align:'center',
                            width: 150,
                            key: 'money',
                            render: (h, params) => {
                                let  money = 0;
                                if ( this.userInfo.type != 2 && this.userInfo.type != 3) {

                                        money = params.row.money ? params.row.money : 0;
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {

                                                if (this.userInfo.type != 2 && this.userInfo.type != 3) {
                                                       this.showMoneyInfo(params.row)
                                                }

                                            }
                                        }
                                    }, money)
                                ]);
                            }
                        },
                        {
                            title: '上级会员',
                            align:'center',
                            width: 150,
                            key: 'up_name'
                        },
                        {
                            title: '服务商',
                            align:'center',
                            width: 200,
                            key: 'channels_name'
                        },
                        {
                            title: '预约单',
                            align:'center',
                            width: 120,
                            key: 'num',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                if (this.userInfo.type != 2 && this.userInfo.type != 4 ) {
                                                    this.showNums(params.row)
                                                }
                                            }
                                        }
                                    }, '查看')
                                ]);
                            }
                        },
                        {
                            title: '入账详情',
                            align:'center',
                            width: 120,
                            key: 'num',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                if ( this.userInfo.type != 3) {
                                                       this.showInfo(params.row)
                                                }
                                            }
                                        }
                                    }, '查看')
                                ]);
                            }
                        },
                        {
                            title: '分享开关',
                            align:'center',
                            width: 150,
                            key: 'share_status',
                            render:(h, params) => {

                                if (this.userInfo.type != 2 && this.userInfo.type != 3 && this.userInfo.type != 4) {
                                       return h('div', [
                                            h('i-switch', { //数据库1是已处理，0是未处理
                                                props: {
                                                    type: 'primary',
                                                    value: params.row.share_status === 1 //控制开关的打开或关闭状态，官网文档属性是value
                                                },
                                                style: {
                                                    marginRight: '5px'
                                                },
                                                on: {
                                                    'on-change': (value) => {//触发事件是on-change,用双引号括起来，
                                                        //参数value是回调值，并没有使用到
                                                        this.switchs(params.row) //params.index是拿到table的行序列，可以取到对应的表格值
                                                    }
                                                }
                                            }, "开启")
                                        ]);
                                }

                            }
                        },
                        {
                            title: '注册时间',
                            align:'center',
                            width: 210,
                            key: 'ctime'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            fixed: 'right',
                            render: (h, params) => {
                                // userInfo
                                let btnArr = new Array();
                                let editBtn = h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.edit(params.row);
                                            }
                                        }
                                    }, '编辑');
                                let delBtn =
                                h('Button', {
                                    props: {
                                        type: 'error',
                                        size: 'small',
                                        marginRight: '5px'
                                    },
                                    on: {
                                        click: () => {
                                            this.remove(params.row)
                                        }
                                    }
                                }, '删除');
                                if (this.userInfo.type != 2 && this.userInfo.type != 3 && this.userInfo.type != 4) {
                                    btnArr.unshift(delBtn);
                                }
                                if (this.userInfo.type != 4) {
                                    btnArr.unshift(editBtn);
                                }

                                return h('div', btnArr);
                            }
                        }
                    ],
                    data: [ ],
                },
                show_nums:false,
                show_nums_title:"预约单",
                nums_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '用户',
                            key: 'member_name',
                            width: 100,
                            align:'center'
                        },
                        {
                            title: '预约电话',
                            align:'center',
                            width: 150,
                            key: 'member_phone'
                        },
                        {
                            title: '预约地点',
                            align:'center',
                            width: 200,
                            key: 'place_name'
                        },
                        {
                            title: '预约日期',
                            align:'center',
                            width: 130,
                            key: 'book_date'
                        },
                        {
                            title: '预约时间',
                            align:'center',
                            width: 100,
                            key: 'book_time'
                        },
                        {
                            title: '到达时间',
                            align:'center',
                            width: 200,
                            key: 'sure_time'
                        },
                        {
                            title: '预约人员',
                            width: 150,
                            align:'center',
                            key: 'doctor_name'
                        },
                        {
                            title: '拒绝原因',
                            key: 'reason',
                            width: 200,
                            align: 'center',
                            render: (h, params) => {

                                return h('div', [
                                    h('textarea', {
                                        style: {
                                            marginRight: '5px'
                                        }
                                    }, params.row.reason)
                                ]);
                            }
                        },
                        {
                            title: '状态',
                            align:'center',
                            width: 150,
                            fixed: 'right',
                            key: 'status',
                            render: (h, params) => {
                                let status_text = "待处理",
                                    status_type = "dashed";
                                switch(params.row.status){
                                    case 0:
                                        status_text = "待处理";
                                        break;
                                    case 1:
                                        status_type = "success";
                                        status_text = "预约成功";
                                        break;
                                    case 2:
                                        status_type = "error";
                                        status_text = "预约失败";
                                        break;
                                }
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: status_type,
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                    }, status_text)
                                ]);
                            }
                        }
                    ],
                    data: [
                    ],
                },
                show_money:false,
                member_id:0,
                money_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    id:0,
                    columns: [{
                        title: '#',
                        type: 'index',
                        align:'center',
                        width: 50
                    },{
                        title: '类型',
                        key: 'name',
                        align:'center',
                        render: (h, params) => {
                            let type_name = "", type_color = "";
                            if (params.row.type == 1) {
                                type_name = "入账分销";
                                type_color = "primary";
                            } else {
                                type_name = "提现";
                                type_color = "error";
                            }
                            return h('div', [
                                h('Button', {
                                    props: {
                                        type: type_color,
                                        size: 'small'
                                    },
                                    style: {
                                        marginRight: '5px'
                                    },
                                }, type_name)
                            ]);
                        }
                    },
                        {
                            title: '资金',
                            align:'center',
                            key: 'money'
                        },
                        {
                            title: '变动时间',
                            align:'center',
                            key: 'ctime'
                        },
                        {
                            title: '备注',
                            align:'center',
                            key: 'reason',
                            render: (h, params) => {
                                let content = "";
                                if (params.row.type == 1) {
                                    content = "会员" + params.row.name+"下单入账"
                                }
                                return h('div', [
                                    h('span', {
                                        style: {
                                            marginRight: '5px'
                                        },
                                    }, content)
                                ]);
                            }
                        }

                    ],
                    data: [
                        {}
                    ],
                }

            }
        },
        mounted(){
            this.userInfo = this.$store.state.userInfo;
            this.loadList();
            this.getUpPerson();
            this.getItemList();
        },
        methods: {

            loadCustomerList(){
                book_ListApi({limit:this.nums_table.limit, page:this.nums_table.current, type:2, member_id : this.member_id}).then(res=>{
                    this.nums_table.data = res.data.list;
                    this.nums_table.count = res.data.count;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            getItemList(){
                itemsApi().then(res=>{
                    this.itemList = res.data;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            showInfo(e){
                this.entry_data = [];
                this.show_info = true;
                this.getEnter(e.id);
            },
            getUpPerson(){
                member_selectApi({type:1}).then(res=>{
                    this.selects.members = res.data.member;
                    this.selects.channels = res.data.channel;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            showNums (e) {
                this.show_nums = true;
                this.member_id = e.id;
                this.loadCustomerList();
            },
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                member_listApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            },
            switchNumsPage(){
                this.nums_table.current = index;
                this.loadCustomerList();
            },
            cancel(){
                this.show_edit = false;
            },
            edit(p){
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = {
                    name:p.name,
                    phone:p.phone,
                    account:p.account,
                    id:p.id,
                    channels_id:p.channels_id,
                    up_id:p.up_id,
                }
            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    name:"",
                    phone:"",
                    account:"",
                    channels_id:"",
                    up_id:""
                }
            },
            save(){
                if(this.submitForm.name == "") {
                    this.$Message.error("姓名不能为空");return;
                }
                if(this.submitForm.phone == "") {
                    this.$Message.error("手机号不能为空");return;
                }
                member_addApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            remove (params) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该会员？</p>',
                    onOk: () => {
                        this.delMember(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            delMember(id){
                member_delApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            getEnter(id){
                entry_getApi({id:id}).then(res=>{
                    this.entry_data = res.data;
                    for(let i in this.entry_data){
                        let entry_d = this.entry_data[i];
                        if (entry_d.items != "") {
                            let items_arr = entry_d.items.split(",");
                            entry_d.item_name = "";
                            for(let i in  this.itemList) {
                                for(let j in items_arr) {
                                    if ( this.itemList[i].id == items_arr[j] ){
                                        entry_d.item_name += this.itemList[i].name + "、  ";
                                    }
                                }
                            }
                        }
                    }
                }).catch(res=>{
                    this.entry_data = [];
                    this.$Message.error(res.msg);

                })
            },
            showMoneyInfo(e){
                this.show_money = true;
                this.money_table.id = e.id
                this.getMoneyList();
            },
            getMoneyList(){
                order_infoApi({id:this.money_table.id, limit:this.money_table.limit, page:this.money_table.current}).then(res=>{
                    this.money_table.count = res.data.count;
                    this.money_table.data = res.data.list;
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            },
            switchMoneyPage(index){
                this.money_table.current = index;
                this.getMoneyList();
            },
            preview(src){
                this.visible = true;
                this.imgSrc = src;
            },
            switchs(e){
                member_shareApi({id:e.id, status:e.share_status == 0 ? 1 : 0}).then(res=>{
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);
                })
            }
        }
    }
</script>
<style scoped>
    .time{
        font-size: 14px;
        font-weight: bold;
    }
    .content{
        padding-left: 5px;
    }
</style>
