<template>
    <div>
        <Form ref="formInline" :model="formInline"  inline>
            <FormItem prop="name">
                <Input type="text" v-model="formInline.name" placeholder="请输入人员名称">
                </Input>
            </FormItem>
            <FormItem>
                <Button type="primary" @click="loadList()" >搜索</Button>
                <Button type="error" @click="reset()" style="margin-left:10px" >重置</Button>
            </FormItem>
        </Form>
         <Button type="success" @click="add()" style="margin-bottom:10px" >新增</Button>

         <tablePage :columns="main_table.columns" :limit="main_table.limit" :data="main_table.data" :count="main_table.count" :current="main_table.current" @switchPage="switchPage" ></tablePage>

        <Modal
            v-model="show_edit" :mask-closable="false" footer-hide
            :title="show_title">
            <Form  :model="submitForm"  :label-width="80">
                <FormItem label="人员名称" prop="name">
                    <Input type="text" v-model="submitForm.name" placeholder="请输入人员名称">
                    </Input>
                </FormItem>
                <FormItem label="人员专业" prop="major">
                    <Input type="text" v-model="submitForm.major" placeholder="请输入人员专业">
                    </Input>
                </FormItem>
                <FormItem label="人员简介" prop="intro">
                    <Input type="textarea" v-model="submitForm.intro" placeholder="请输入人员简介">
                    </Input>
                </FormItem>
                <FormItem label="头像" prop="avatar" >
                     <Upload :before-upload="handleUpload" action="" :accept="Accept" :format="Format">
                        <Button icon="ios-cloud-upload-outline">选择头像(100*100)</Button>
                    </Upload>
                    <img :src="submitForm.avatar" style="width:100px;height:100px" />
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="save()" style="margin-left:10px" >保存</Button>
                    <Button type="error" @click="cancel()" style="margin-left:10px" >取消</Button>
                </FormItem>
            </Form>
        </Modal>

    </div>
</template>
<script>
    import {book_addDoctorApi, book_doctorListApi, book_delDoctorApi} from '@/api/book';
    import {tablePage} from '@/components/iview';
    export default {
        components:{
            tablePage
        },
        data () {
            return {
                Accept:"png,jpg,jpeg",
                Format: ["jpg", "jpeg", "png"],
                submitForm:{
                    name:"",
                    major:"",
                    intro:"",
                    avatar:""
                },
                show_edit:false,
                show_title:"新增",
                main_table:{
                    current:1,
                    count: 30,
                    limit:30,
                    columns: [{
                            title: '#',
                            type: 'index',
                            width: 80,
                            align:'center'
                        },{
                            title: '姓名',
                            key: 'name',
                            width: 80,
                            align:'center'
                        },
                        {
                            title: '头像',
                            key: 'avatar',
                            width: 100,
                            align:'center',
                            render: (h, params) => {
                                return h('div', [
                                h('img', {
                                        attrs: {
                                            src: params.row.avatar
                                        },
                                        style: {
                                            margin:'5px',
                                            width: '50px',
                                            height: '50px'
                                        }
                                    })
                                ]);
                            }
                        },
                        {
                            title: '专业',
                            align:'center',
                            width: 300,
                            key: 'major'
                        },
                        {
                            title: '简介',
                            align:'center',
                            key: 'intro'
                        },
                        {
                            title: '操作',
                            key: 'action',
                            width: 150,
                            align: 'center',
                            render: (h, params) => {
                                return h('div', [
                                    h('Button', {
                                        props: {
                                            type: 'primary',
                                            size: 'small'
                                        },
                                        style: {
                                            marginRight: '5px'
                                        },
                                        on: {
                                            click: () => {
                                                this.show(params.row)
                                            }
                                        }
                                    }, '编辑'),
                                    h('Button', {
                                        props: {
                                            type: 'error',
                                            size: 'small'
                                        },
                                        on: {
                                            click: () => {
                                                this.remove(params.row)
                                            }
                                        }
                                    }, '删除')
                                ]);
                            }
                        }
                    ],
                    data: [
                    ],
                },
                formInline:{}
            }
        },
        mounted(){
            this.loadList();
        },
        methods: {
            handleUpload(v){
                 let reader = new FileReader()
                reader.readAsDataURL(v)
                reader.onload = e => {
                    this.submitForm.avatar = e.currentTarget.result
                }
            },

            cancel(){
                this.show_edit = false;
            },
            add(){
                this.show_edit = true;
                this.show_title = "新增";
                this.submitForm = {
                    name:"",
                    major:"",
                    intro:"",
                    avatar:""
                }
            },
            save(){
                if(this.submitForm.name == "") {
                    this.$Message.error("人员姓名不能为空");return;
                }
                if(this.submitForm.avatar == "") {
                    this.$Message.error("人员头像不能为空");return;
                }
                book_addDoctorApi(this.submitForm).then(res=>{
                    this.show_edit = false;
                    this.loadList();
                    this.$Message.success(res.msg);
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            show (params) {
                this.show_edit = true;
                this.show_title = "编辑";
                this.submitForm = params;
            },
            remove (params) {
                this.$Modal.confirm({
                    title: '提示',
                    content: '<p>是否要删除该人员？</p>',
                    onOk: () => {
                        this.delDoctor(params.id);
                    },
                    onCancel: () => {
                    }
                });
            },
            delDoctor(id){
                book_delDoctorApi({id:id}).then(res=>{
                    this.$Message.success(res.msg);
                    this.loadList();
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })
            },
            reset(){
                this.formInline = {
                    name:""
                }
                this.main_table.current = 1;
                this.loadList();
            },
            loadList(){
                this.formInline.limit = this.main_table.limit;
                this.formInline.page = this.main_table.current;
                book_doctorListApi(this.formInline).then(res=>{
                    this.main_table.count = res.data.count;
                    this.main_table.data = res.data.list;
                    console.log(res)
                }).catch(res=>{
                    this.$Message.error(res.msg);return;
                })

            },
            /**切换页面 */
            switchPage(index){
                this.main_table.current = index;
                this.loadList();
            }
        }
    }
</script>
